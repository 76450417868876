<template>
  <v-container>
    <v-dialog transition="dialog-bottom-transition" width="auto">
      <template v-slot:activator="{ props: activatorProps }">
        <button
          v-if="route == `dashboard`"
          v-bind="activatorProps"
          class="card-buttons-preview"
        >
          <img
            src="@/assets/images/content/preview.png"
            alt=""
            class="card-buttons-preview-img"
          />
          <div class="card-buttons-preview-text">پیش نمایش</div>
        </button>

        <button
          v-bind="activatorProps"
          v-if="route == `edit`"
          class="preview-btn"
          variant="plain"
        >
          <img class="view-img" src="@/assets/images/content/View.png" alt="" />
        </button>
      </template>

      <template v-slot:default="{ isActive }">
        <v-card class="frame-card">
          <v-card-actions
            class="modal-header d-flex  justify-space-between align-center"
          >
            <v-icon @click="isActive.value = false">mdi-close-circle-outline</v-icon>
            <div class="form-title">پیش نمایش</div>

          </v-card-actions>

          <iframe class="iframe" :src="link" frameborder="0"></iframe>
        </v-card>
      </template>
    </v-dialog>
  </v-container>
</template>
<style lang="scss">
.modal-header {
  font-family:'morabba-regular','poppins-regular' ;
  box-shadow: 0px 1px 5px 0px #959595;
  height: 54px !important;
  position: inherit;
  .form-title{
    padding-left: 24px;
  }
}
.view-img {
  width: 40px !important;
  height: 40px !important;
}
.card-buttons-preview {
  min-width: 120px;
  border: 1.9px solid #009606;
  border-radius: 8px;
  height: 50px;
  width: 100%;
  background: #f0fcf3;
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  gap: 16px;
  padding-right: 10px;
  &-text {
    color: #009606;
    font-size: 14px;
    font-weight: 400;
  }
}
.preview-btn {
  border-radius: 50%;
  padding-left: 60px;
}
.v-overlay__content {
  width: 100% !important;
  height: 100% !important;
  max-width: 100% !important;
  max-height: 100% !important;
  padding-top: 50px !important;
}
.v-container {
  width: 40%;
  padding: 0px;
  margin-right: unset;
  margin-left: unset;
}
.frame-card {
  height: 100%;
}
.iframe {
  height: 100%;
  overflow-x: hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    width: 0 !important;
    height: 0 !important;
  }
}
</style>
<script>
export default {
  name: "previewModal",
  props: {
    link: String,
    route: String,
  },
};
</script>
