<template>
  <div v-if="submited" class="form">
    <div class="login-box">
      <img :src="supportIcon" alt="" />
      <form @submit.prevent="validateForm">
        <div class="user-box">
          <input v-model="name" type="text" name="" placeholder="نام و نام خانوادگی" />
        </div>
        <div class="user-box">
          <input
          class="mobileNumberInput"
            v-model="mobile"
            placeholder="شماره تلفن"
            @input="validateInput"
            maxlength="12"
            type="number"
            name=""
          />
        </div>
        <div class="user-box">
          <input v-model="restaurantName" placeholder="نام کافه" type="text" name="" />
        </div>
        <button class="submit" type="submit">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          تایید
        </button>
      </form>
    </div>
  </div>
</template>
<style scoped>
@import "./styles/index.scss";
</style>
<script>
/* eslint-disable */
import axios from "axios";
import router from "@/router";
import campaignSubmit from "@/services/campaignSubmit.js"
export default {
  name: "campaignFrom",
  data: () => ({
    submited: true,
    supportIcon: "../assets/images/icons/male-services-support-icon.svg",
    name: "",
    mobile: "",
    restaurantName: "",
    baseNumber: true,
  }),

  methods: {
    submit(){
      var data = new FormData();
      data.append("name",this.name)
      data.append("phone",this.mobile.toString()) 
      data.append("cafeName",this.restaurantName) 
      axios(campaignSubmit(data))
              .then((response) => {
                this.$swal({
                  position: "center",
                  icon: "success",
                  title: "درخواست پشتیبانی با موفقیت ثبت شد.",
                  timer: 1000,
                }).then(() => {
            router.replace("cv.menu")
            
          });
              })
              .catch((error) => {
                console.log(error);
              });
    },
    validateInput() {
      this.mobile="0"+this.mobile
      this
      this.mobile = this.mobile.toString().replace(/\D/g, ""); // حذف هر کاراکتری که عدد نیست

      if (!this.mobile.toString().startsWith("09")) {
        this.baseNumber = false;
      } else {
        this.baseNumber = true;
      }

      if (this.mobile.length > 11) {
        this.mobile = this.mobile.slice(0, 11);
      }
    },
    validateForm() {
      if (!this.baseNumber) {
        this.$swal({
              position: "center",
              icon: "warning",
              title: "شماره موبایل باید با 09 شروع شود.",
              showConfirmButton: false,
              timer: 1500,
            });
      } else if (this.mobile.length !== 11) {
        this.$swal({
              position: "center",
              icon: "warning",
              title: "لطفا شماره صحیح وارد کنید.",
              showConfirmButton: false,
              timer: 1500,
            });
      } else if(localStorage.getItem("submited")==null){
        localStorage.setItem("submited",true)
        this.submit()
      }else{
        this.$swal({
              position: "center",
              icon: "warning",
              title: "شما قبلا یکبار درخواست دادید.",
              showConfirmButton: false,
              timer: 1500,
            });
      }
    },
  },
};
</script>
