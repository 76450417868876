<template>
  <div class="customer-club" component="customerClub">
    <div
      class="customer-club-form"
      :style="`background:${this.option.option1}`"
    >
      <div class="customer-club-form-inputs">
        <v-locale-provider
          v-for="(input, index) in clubData[0].inputs"
          :key="input.id"
          rtl
        >
          <v-text-field
            :type="index == 0 ? 'number' : 'text'"
            :hint="input.require?'الزامی':''"
            hide-spin-buttons
            :label="input.name"
            v-model="input.value"
            :require="input.require"
            :color="this.option.borderBlockItemColor"
            :base-color="this.option.borderBlockItemColor"
            :style="`color:${this.option.textBlockItemColor}`"
            class="customer-club-form-inputs-input"
            variant="outlined"
            hide-details="auto"
            persistent-hint
            clearable
          ></v-text-field>
        </v-locale-provider>
        <div
          v-if="disableInternalEvents"
          class="overlay"
          @click="coustomerEdit"
        ></div>
      </div>
      <div
        class="customer-club-form-sexInput"
        v-if="clubData[0].sexVisibility == 'true'"
      >
        <input
          checked
          :style="`accent-color:${this.option.bgBlockItemColor} ;`"
          type="radio"
          name="sex"
          v-model="sex"
          id="male"
          value="آقا"
        />
        <label for="male" :style="`color:${this.option.blockTitle}`">آقا</label>
        <input
          :style="`accent-color:${this.option.bgBlockItemColor} ;`"
          type="radio"
          name="sex"
          v-model="sex"
          id="female"
          value="خانوم"
        />
        <label for="female" :style="`color:${this.option.blockTitle}`"
          >خانم</label
        >
      </div>
      <div class="customer-club-form-submit">
        <button
          @click="submit"
          :style="`background:${this.option.bgBlockItemColor};color:${this.option.option2}`"
          class="customer-club-form-submit-btn"
        >
          {{ club[0].submitName }}
        </button>
      </div>
    </div>
  </div>
</template>
<style lang="scss">
@import "./styles/index.scss";
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  cursor: pointer;
}
</style>
<script>
/* eslint-disable */
import axios from "axios";
import submitSubscription from "@/services/submitSubscription.js";
export default {
  name: "customerClub",
  data: () => ({
    clubData: [],
    sex: "آقا",
  }),
  beforeMount() {
    this.clubData = JSON.parse(JSON.stringify(this.club));
  },
  methods: {
    submit() {
      if (!this.isSubmitDisabled) {
        let data = {
          profile_id: this.profile_id,
          block_club_id: this.club[0].id,
        };

        let formInputs = [];
        this.clubData[0].inputs.forEach((input) => {
          if (input.value) {
            formInputs.push({
              name: input.name,
              value: input.value,
              id: input.id,
            });
          }
        });
        if (this.clubData[0].sexVisibility == "true") {
          formInputs.push({
            name: "جنسیت",
            value: this.sex,
          });
        }

        data.formInputs = formInputs;
        axios(submitSubscription(data))
          .then((response) => {
            this.$swal({
            position: "center",
            icon: "success",
            title: "اطلاعات شما با موفقیت ثبت شد",
            showConfirmButton: false,
            timer: 1000,
          })
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        this.$swal({
          position: "center",
          icon: "error",
          title: "لطفا فیلد های الزامی را پر کنید",
          showConfirmButton: true,
        });
      }
    },
    coustomerEdit() {
      if (this.disableInternalEvents) {
        event.stopPropagation(); // جلوگیری از انتشار ایونت داخلی
        this.$emit("click", event); // انتشار ایونت به والد
        return;
      }
    },
  },
  computed: {
    isSubmitDisabled() {
      return this.clubData[0].inputs.some(
        (input) => input.require && !input.value
      );
    },
  },

  props: {
    club: Object,
    disableInternalEvents: {
      type: Boolean,
      default: false,
    },
    profile_id: Number,
    option: Object,
  },
};
</script>
