<template>
  <div class="add-page-modal" component="addPageModal">
    <div class="modal">
      <div class="add-card">
        <v-card class="card">
          <v-card-actions class="flex-row d-flex justify-space-between align-center">
              <div class="modal-header-text">
                <div class="modal-header-text-title">بارگذاری اطلاعات</div>
                <div class="modal-header-text-sub">
                  از صفحه ساز ایزی کانکت لذت ببرید !
                </div>
              </div>
              <v-icon @click="$emit('showModal')">mdi-close-circle-outline</v-icon>
          </v-card-actions>

          <div class="add-page-form">
            <div class="input">
              <div class="hostName" style="color: #009606">
                www.{{ hostName }}/
              </div>
              <input
                type="text"
                :value="link"
                @input="handleInput"
                placeholder="نام کاربری"
                :style="`border:2px solid ${color}`"
              />
            </div>
            <div class="link-preview" :style="`color:${color}`">
              www.{{ hostName }}/{{ link }}
            </div>
            <div class="response">
              <div v-if="validation" :style="`color:${color}`">
                لطفا حداقل سه کارکتر وارد کنید
              </div>
              <div
                style="display: flex; flex-direction: row-reverse; gap: 4px;direction: ltr"
                v-if="!validation"
                v-html="response"
              ></div>
            </div>
            <div class="buttons">
              <v-btn
                :border="true"
                class="card-buttons-edit bg-green-lighten-5 text-green-darken-2"
                outlined
                text
                >لغو</v-btn
              >
              <v-btn color="#009606" @click="submit" :disabled="submitValid"
                >ادامه</v-btn
              >
            </div>
          </div>
        </v-card>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
@import "./styles/index.scss";
</style>

<script>
/* eslint-disable */
import axios from "axios";
import router from "@/router";
import linkConfig from "@/services/links.js";
import submitPage from "@/services/submitPage.js";

export default {
  name: "addPageModal",
  data: () => ({
    step: 1,
    hostName: location.hostname,
    link: "",
    response: "",
    links: [],
    validation: null,
    submitValid: true,
    color: "#CC0000",
  }),
  methods: {
    handleInput(evt) {
      this.link = evt.target.value;
      this.checkValid();
    },
    checkValid() {
      this.link = this.link.toLowerCase();
      const regex = /^[a-z0-9\-_+]+$/i;
if (!regex.test(this.link)) {
  this.link = this.link.replace(/[^a-z0-9\-_+]/g, "");
}
      if (this.link.length < 3) {
        this.validation = true;
        this.submitValid = true;
        this.color = "#CC0000";
      } else {
        this.validation = false;
        if (this.links.findIndex((element) => element == this.link) >= 0) {
          this.response =
            "نام کاربری <div class='highlight'>تکراری</div> است نام دیگری انتخاب کنید";
          this.submitValid = true;
          this.color = "#CC0000";
        } else {
          this.response =
            "<div class='green-highlight'>این لینک آزاد است</div>";
          this.submitValid = false;
          this.color = "#009606";
        }
      }
    },
    submit() {
      var FormData = require("form-data");
      var data = new FormData();
      data.append("link", this.link);
      data.append("user_uuid", localStorage.getItem("uuid"));
      // data.append("profile_id", localStorage.getItem("profile_id"))

      axios(submitPage(data))
        .then((response) => {
          router.push("/edit/"+this.link)
          
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {
    var FormData = require("form-data");
    var data = new FormData();
    data.append("user_uuid", localStorage.getItem("uuid"));
    // data.append("profile_id", localStorage.getItem("profile_id"))

    axios(linkConfig(data))
      .then((response) => {
        this.links = response.data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
};
</script>
