import baseUrl from "./baseUrl";

function dashboardConfig(data) {
  return {
    method: 'post',
    url: `${baseUrl}/api/v1/dashboard`,
    headers: { 
       'Accept': 'application/json', 
    },
    data : data
 }

  
}

export default dashboardConfig;
