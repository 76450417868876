<template>
    <div class="page-creator-header" component="pageCreatorHeader">
        <div class="header">
            <div class="header-icon">
                <v-img class="header-icon-img" src="./assets/images/icons/ezy-icon.jpg"></v-img>
            </div>
            <div class="header-title">
                <p class="header-title-text">EZY CONNECT</p>
            </div>
            <div @click="$emit('showSlideMenu')" class="header-menu">
                <v-icon class="header-menu-icon">mdi-menu</v-icon>
            </div>
        </div>
        <v-divider></v-divider>
    </div>
</template>

<style lang="scss">
@import "./styles/index.scss";
</style>
<script>
export default {
    name: 'pageCreatorHeader',
}
</script>
