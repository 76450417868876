<template>
    <div class="routing-block" component="routingBlock">
        <div class="routing-block-content">
            <div class="routing-block-content-iframe">
                <iframe :src="this.iframeLink" frameborder="0"></iframe>
            </div>
            <a :href="this.geoLink" class="routing-block-content-button">
                <button>مسیریابی <v-icon>mdi-map-marker-radius</v-icon></button>
            </a>
        </div>
        <div v-if="disableInternalEvents" class="overlay"></div>
    </div>
</template>
<style>

</style>
<style lang="scss">
@import "./styles/index.scss";
</style>

<script>
export default {
    name:"routingMap",
    data:()=>({
        iframeLink:"",
        geoLink:""
    }),
    props:{
        mapData:Object,
        disableInternalEvents:Boolean
    },
    beforeMount(){
        this.iframeLink=`https://map.ir/lat/${this.mapData[0].position[1]}/lng/${this.mapData[0].position[0]}/z/17/p/${this.mapData[0].title}`;
        this.geoLink=`geo:${this.mapData[0].position[1]},${this.mapData[0].position[0]}`
    }
};
</script>
