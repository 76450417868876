<template>
  <div class="pageCard" component="page-card">
    <div class="card" outlined>
      <div class="card-nav">
        <div class="card-nav-link">
          <div class="card-nav-link-icons">
            <div class="card-nav-link-icons-copy" @click="doCopy">
              <v-icon
                class="card-nav-link-icons-copy-img"
                src="./assets/images/content/copy.png"
                >mdi-content-copy</v-icon
              >
            </div>
            <div class="card-nav-link-icons-share" @click="share">
              <v-icon
                class="card-nav-link-icons-share-img"
                src="./assets/images/content/share.png"
                >mdi-share-variant-outline</v-icon
              >
            </div>
          </div>
          <div class="card-nav-link-address" :id="link">
            {{ hostname + "/" + link }}
          </div>
        </div>
        <div class="card-nav-menu">
          <v-btn
          variant="plain"
          icon
          >
          <v-icon color="black">mdi-dots-vertical</v-icon>
            <!-- <v-menu activator="parent">
              <v-list>
                <v-list-item
                >
                  <v-list-item-title><v-btn>جابجایی</v-btn></v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu> -->
          </v-btn>
        </div>
      </div>
      <div class="card-item" three-line>
        <div class="card-item-content">
          <v-list-item-title class="card-item-content-title">
            {{ title }}
          </v-list-item-title>
          <v-list-item-subtitle class="card-item-content-sub">{{
            subTitle
          }}</v-list-item-subtitle>
        </div>

        <div class="card-item-avatar" tile size="80">
          <v-img class="card-item-avatar-img" lazy-src="./assets/images/content/defaultProfile.png" :src="image" />
        </div>
      </div>

      <v-card-actions class="card-buttons">
        <!-- موقع بیلد فراموش نکن لینک رو درست کنی -->
        <previewModal route="dashboard" :link="`https://` +hostname+'/'+ link" />
        <button
          
          class="card-buttons-edit"
          
          @click="getEdit()"
          
        >
        <img src="@/assets/images/content/edit.png" class="card-buttons-edit-img" alt="">
          <div class="card-buttons-edit-text">ویرایش</div>
        </button>
      </v-card-actions>
    </div>
    <qr-card @closeQr="closeQr" v-show="showQr" :text="`https://` +hostname+'/'+ link" />
  </div>
</template>
<style lang="scss">
@import "./styles/index.scss";
</style>
<!-- <script setup lang="ts">

</script> -->
<script>
/* eslint-disable */
import axios from "axios";
import router from "@/router/index.js";
import qrCard from "@/components/qrCard/index.vue";
import previewConfig from "@/services/preview";
import previewModal from "@/components/preview/index.vue"
const Swal = require("sweetalert2");

export default {
  name: "pageCard",
  data: () => ({
    hostname: window.location.hostname,
    address: window.location.origin,
    showQr: false,
  }),
  props: {
    link: String,
    image: String,
    title: String,
    subTitle: String,
    profileId:String,
    profile_id:Number,
  },
  components: {
    qrCard,
    previewModal,
  },
  methods: {
    doCopy() {
      this.$copyText(`https://` +this.hostname+'/'+ this.link).then(
        function (e) {
          Swal.fire({
            position: "top-center",
            title: "کپی شد",
            showConfirmButton: false,
            fontSize: "20",
            width: "130",
            heightAuto: false,
            background: "rgb(208, 255, 208)",
            timer: 500,
          });
        },
        function (e) {}
      );
    },

    share() {
      this.showQr = true;
    },
    closeQr() {
      this.showQr = false;
    },
    getPreview(link) {
      const formData = new FormData();
      formData.append("link", link);
      formData.append("user_uuid", localStorage.getItem("uuid"))
      data.append("profile_id", this.profile_id)
      formData.append("profile_id", this.profile_id)


      axios(previewConfig(formData))
        .then((response) => {
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getEdit(){
      localStorage.setItem("profile_id", this.profileId)
      router.push(`/edit/${this.link}`); 
    }
  },
};
</script>
