<template>
<page-Creator-Component />
</template>
<script>
import pageCreatorComponent from '@/components/pageCreator/index.vue'

export default {
    name: 'pageCreator',
    components:{
        pageCreatorComponent
    }
}
</script>