<template>
  <div class="visit-blocks" component="visitBlocks">
    <div :style="widthStyle" class="elements">
      <visitBlock
      :profileOption="profileOption"
        v-for="element in elements"
        :title="element.pivot.title"
        :link="element.link"
        :connectionWay="element.pivot.connectionWay"
        :defaultColor="element.color"
        :blockItemColor="options.blockItemColor"
        :bgBlockItemColor="options.bgBlockItemColor"
        :textBlockItemColor="options.textBlockItemColor"
        :borderBlockItemColor="options.borderBlockItemColor"
        :blockBorder="options.blockBorder"
        :key="element.id"
        :icon="element.icon"
        :width="options.blockWidth"
      />
    </div>
  </div>
</template>
<style lang="scss">
@import "./styles/index.scss";
</style>
<script>
import visitBlock from "@/components/visitBlock/index.vue";
export default {
  name: "ezyVisitBlocks",
  data: () => ({}),
  components: {
    visitBlock,
  },
  computed: {
    widthStyle() {
      if (this.options.blockWidth === "full") {
        return `
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 8px;
      `;
      } else if (this.options.blockWidth === "half") {
        return `
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: 8px;
      `;
      } else if (this.options.blockWidth === "compress") {
        return `
              display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
  justify-content: center;`;
      } else {
        return ``;
      }
    },
  },
  props: {
    elements: Object,
    options: Object,
    blockTitle: String,
    profileOption: Object,
  },
};
</script>
