<template>
<div class="loading-component" componnet="loadingComponent">
  <div class="loading" id="loading">
  <svg id="triangle" viewBox="0 0 226 226" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="1.5" >
    <g class="group--1">
      <path class="triangle" id="triangle-1-1"
            d=" M 22.5 180.1
               l -22.5 45
               h 45
               z" />
      <path class="fill" id="fill-1-1"
            d=" M 22.5 180.1
               l -22.5 45
               h 45
               z" />
    </g>
    <g class="group--3">
      <path class="triangle" id="triangle-3-1"
            d=" M 45.07 225.099
               l -22.5 -45
               h 45
               z" />
      <path class="fill" id="fill-3-1" 
            d=" M 45.07 225.099
               l -22.5 -45
               h 45
               z" />
    </g>
    <g class="group--2">
      <path class="triangle" id="triangle-2-1"
            d=" M 67.6 180.1
               l -22.5 45
               h 45
               z" />

      <path class="fill" id="fill-2-1"
            d=" M 67.6 180.1
               l -22.5 45
               h 45
               z" />
    </g>
    <g class="group--4">
      <path class="triangle" id="triangle-4-1"
            d=" M 90.07 225.099
               l -22.5 -45
               h 45
               z" />
      <path class="fill" id="fill-4-1"
            d=" M 90.07 225.099
               l -22.5 -45
               h 45
               z" />
    </g>
    <g class="group--5">
      <path class="triangle" id="triangle-5-1"
            d=" M 112.6 180.1
               l -22.5 45
               h 45
               z" />
      <path class="fill" id="fill-5-1"
            d=" M 112.6 180.1
               l -22.5 45
               h 45
               z" />
    </g>
    <g class="group--6">
      <path class="triangle" id="triangle-6-1"
            d=" M 135.07 225.099
               l -22.5 -45
               h 45
               z" />
      <path class="fill" id="fill-6-1"
            d=" M 135.07 225.099
               l -22.5 -45
               h 45
               z" />
    </g>
    <g class="group--7">
      <path class="triangle" id="triangle-7-1"
            d=" 
               M 45.07 135.1
               l 22.5 45
               h -45
               z " />
      <path class="fill" id="fill-7-1"
            d=" 
               M 45.07 135.1
               l 22.5 45
               h -45
               z" />
    </g>
    <g class="group--8">
      <path class="triangle" id="triangle-8-1"
            d=" M 157.6 180.1
               l -22.5 45
               h 45
               z" />
      <path class="fill" id="fill-8-1"
            d=" M 157.6 180.1
               l -22.5 45
               h 45
               z" />
    </g>
    <g class="group--9">
      <path class="triangle" id="triangle-9-1"
            d=" M 180.07 225.099
               l -22.5 -45
               h 45
               z" />
      <path class="fill" id="fill-9-1"
            d=" M 180.07 225.099
               l -22.5 -45
               h 45
               z" />
    </g>
    <g class="group--10">
      <path class="triangle" id="triangle-10-1"
            d=" M 202.6 180.1
               l -22.5 45
               h 45
               z" />
      <path class="fill" id="fill-10-1"
            d=" M 202.6 180.1
               l -22.5 45
               h 45
               z" />            
    </g>
    <g class="group--11">
      <path class="triangle" id="triangle-11-1"
            d=" M 67.6 180.099
               l 22.5 -45
               h -45
               z" />
      <path class="fill" id="fill-11-1"
            d=" M 67.6 180.099
               l 22.5 -45
               h -45
               z" />
    </g>
    <g class="group--12">
      <path class="triangle" id="triangle-12-1"
            d=" M 180.07 135.1
               l 22.5 45
               h -45
               z " />
      <path class="fill" id="fill-12-1"
            d=" M 180.07 135.1
               l 22.5 45
               h -45
               z " />
    </g>
    <g class="group--13">
      <path class="triangle" id="triangle-13-1"
            d=" M 67.6 90.1
               l 22.5 45
               h -45
               z "/>
      <path class="fill" id="fill-13-1"
            d=" M 67.6 90.1
               l 22.5 45
               h -45
               z "/>
    </g>
    <g class="group--14">
      <path class="triangle" id="triangle-14-1"
            d=" M 157.6 180.099
               l -22.5 -45
               h 45
               z" />
      <path class="fill" id="fill-14-1"
            d=" M 157.6 180.099
               l -22.5 -45
               h 45
               z" />
    </g>
    <g class="group--15">
      <path class="triangle" id="triangle-15-1"
            d=" M 157.6 90.1
               l 22.5 45
               h -45
               z "/>
      <path class="fill" id="fill-15-1"
            d=" M 157.6 90.1
               l 22.5 45
               h -45
               z "/>
    </g>
    <g class="group--16 group--16-1" id="group-16-1">
      <path class="triangle" id="triangle-16-1-1"
            d=" M 90.07 135.099
               l 22.5 -45
               h -45
               z" />
      <path class="fill fill--16" id="fill-16-1-1"
            d=" M 90.07 135.099
               l 22.5 -45
               h -45
               z" />
    </g>
    <g class="group--16 group--16-2" id="group-16-2">
      <path class="triangle" id="triangle-16-2-1"
            d=" 
               M 135.07 135.099
               l -22.5 -45
               h 45
               z" />
      <path class="fill fill--16" id="fill-16-2-1"
            d=" 
               M 135.07 135.099
               l -22.5 -45
               h 45
               z" />
    </g>
    <g class="group--17">
      <path class="triangle" id="triangle-17-1"
            d=" M 135.07 45
               l 22.5 45
               h -45
               z " />
      <path class="fill" id="fill-17-1"
            d=" M 135.07 45
               l 22.5 45
               h -45
               z " />
    </g>
    <g class="group--18">
      <path class="triangle" id="triangle-18-1"
            d=" M 90.07 45
               l 22.5 45
               h -45
               z " />
      <path class="fill" id="fill-18-1"
            d=" M 90.07 45
               l 22.5 45
               h -45
               z " />
    </g>
    <g class="group--19">
      <path class="triangle" id="triangle-19-1"
            d=" M 135.07 45
               h -45
               l 22.5 45
               z " />
      <path class="fill" id="fill-19-1"
            d=" M 135.07 45
               h -45
               l 22.5 45
               z " /> 
    </g>
    <g class="group--20">
      <path class="triangle" id="triangle-20-1"
            d=" M 112.6.1
               l 22.5 45
               h -45
               z "/>
      <path class="fill" id="fill-20-1"
            d=" M 112.6.1
               l 22.5 45
               h -45
               z "/>
    </g>
  </svg>
  <svg id="triangle-2" viewBox="0 0 226 226" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="1.5" >
    <g class="group--1">
      <path class="triangle" id="triangle-1-2"
            d=" M 22.5 180.1
               l -22.5 45
               h 45
               z" />
      <path class="fill" id="fill-1-2"
            d=" M 22.5 180.1
               l -22.5 45
               h 45
               z" />
    </g>
    <g class="group--3">
      <path class="triangle" id="triangle-3-2"
            d=" M 45.07 225.099
               l -22.5 -45
               h 45
               z" />
      <path class="fill" id="fill-3-2" 
            d=" M 45.07 225.099
               l -22.5 -45
               h 45
               z" />
    </g>
    <g class="group--2">
      <path class="triangle" id="triangle-2-2"
            d=" M 67.6 180.1
               l -22.5 45
               h 45
               z" />

      <path class="fill" id="fill-2-2"
            d=" M 67.6 180.1
               l -22.5 45
               h 45
               z" />
    </g>
    <g class="group--4">
      <path class="triangle" id="triangle-4-2"
            d=" M 90.07 225.099
               l -22.5 -45
               h 45
               z" />
      <path class="fill" id="fill-4-2"
            d=" M 90.07 225.099
               l -22.5 -45
               h 45
               z" />
    </g>
    <g class="group--5">
      <path class="triangle" id="triangle-5-2"
            d=" M 112.6 180.1
               l -22.5 45
               h 45
               z" />
      <path class="fill" id="fill-5-2"
            d=" M 112.6 180.1
               l -22.5 45
               h 45
               z" />
    </g>
    <g class="group--6">
      <path class="triangle" id="triangle-6-2"
            d=" M 135.07 225.099
               l -22.5 -45
               h 45
               z" />
      <path class="fill" id="fill-6-2"
            d=" M 135.07 225.099
               l -22.5 -45
               h 45
               z" />
    </g>
    <g class="group--7">
      <path class="triangle" id="triangle-7-2"
            d=" 
               M 45.07 135.1
               l 22.5 45
               h -45
               z " />
      <path class="fill" id="fill-7-2"
            d=" 
               M 45.07 135.1
               l 22.5 45
               h -45
               z" />
    </g>
    <g class="group--8">
      <path class="triangle" id="triangle-8-2"
            d=" M 157.6 180.1
               l -22.5 45
               h 45
               z" />
      <path class="fill" id="fill-8-2"
            d=" M 157.6 180.1
               l -22.5 45
               h 45
               z" />
    </g>
    <g class="group--9">
      <path class="triangle" id="triangle-9-2"
            d=" M 180.07 225.099
               l -22.5 -45
               h 45
               z" />
      <path class="fill" id="fill-9-2"
            d=" M 180.07 225.099
               l -22.5 -45
               h 45
               z" />
    </g>
    <g class="group--10">
      <path class="triangle" id="triangle-10-2"
            d=" M 202.6 180.1
               l -22.5 45
               h 45
               z" />
      <path class="fill" id="fill-10-2"
            d=" M 202.6 180.1
               l -22.5 45
               h 45
               z" />            
    </g>
    <g class="group--11">
      <path class="triangle" id="triangle-11-2"
            d=" M 67.6 180.099
               l 22.5 -45
               h -45
               z" />
      <path class="fill" id="fill-11-2"
            d=" M 67.6 180.099
               l 22.5 -45
               h -45
               z" />
    </g>
    <g class="group--12">
      <path class="triangle" id="triangle-12-2"
            d=" M 180.07 135.1
               l 22.5 45
               h -45
               z " />
      <path class="fill" id="fill-12-2"
            d=" M 180.07 135.1
               l 22.5 45
               h -45
               z " />
    </g>
    <g class="group--13">
      <path class="triangle" id="triangle-13-2"
            d=" M 67.6 90.1
               l 22.5 45
               h -45
               z " />
      <path class="fill" id="fill-13-2"
            d=" M 67.6 90.1
               l 22.5 45
               h -45
               z " />
    </g>
    <g class="group--14">
      <path class="triangle" id="triangle-14-2"
            d=" M 157.6 180.099
               l -22.5 -45
               h 45
               z" />
      <path class="fill" id="fill-14-2"
            d=" M 157.6 180.099
               l -22.5 -45
               h 45
               z" />
    </g>
    <g class="group--15">
      <path class="triangle" id="triangle-15-2"
            d=" M 157.6 90.1
               l 22.5 45
               h -45
               z " />
      <path class="fill" id="fill-15-2"
            d=" M 157.6 90.1
               l 22.5 45
               h -45
               z " />
    </g>
    <g class="group--16 group--16-1" id="group-16-1-2">
      <path class="triangle" id="triangle-16-1-2"
            d=" M 90.07 135.099
               l 22.5 -45
               h -45
               z" />
      <path class="fill fill--16" id="fill-16-1-2"
            d=" M 90.07 135.099
               l 22.5 -45
               h -45
               z" />
    </g>
    <g class="group--16 group--16-2" id="group-16-2-2">
      <path class="triangle" id="triangle-16-2-2"
            d=" 
               M 135.07 135.099
               l -22.5 -45
               h 45
               z" />
      <path class="fill fill--16" id="fill-16-2-2"
            d=" 
               M 135.07 135.099
               l -22.5 -45
               h 45
               z" />
    </g>
    <g class="group--17">
      <path class="triangle" id="triangle-17-2"
            d=" M 135.07 45
               l 22.5 45
               h -45
               z " />
      <path class="fill" id="fill-17-2"
            d=" M 135.07 45
               l 22.5 45
               h -45
               z " />
    </g>
    <g class="group--18">
      <path class="triangle" id="triangle-18-2"
            d=" M 90.07 45
               l 22.5 45
               h -45
               z " />
      <path class="fill" id="fill-18-2"
            d=" M 90.07 45
               l 22.5 45
               h -45
               z " />
    </g>
    <g class="group--19">
      <path class="triangle" id="triangle-19-2"
            d=" M 135.07 45
               h -45
               l 22.5 45
               z " />
      <path class="fill" id="fill-19-2"
            d=" M 135.07 45
               h -45
               l 22.5 45
               z " /> 
    </g>
    <g class="group--20">
      <path class="triangle" id="triangle-20-2"
            d=" M 112.6.1
               l 22.5 45
               h -45
               z "/>
      <path class="fill" id="fill-20-2"
            d=" M 112.6.1
               l 22.5 45
               h -45
               z "/>
    </g>
  </svg>
</div>
</div>
</template>

<style scoped>

.loading-component{
  z-index:999999;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgb(255, 255, 255);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
#loading {
  clip-path: polygon(0% 0%, 0% 100%, 42.3% 100%, 42.3% 71.8%, 49.85% 56.2%, 57.7% 71.8%, 30.6% 71.8%, 27% 100%, 100% 100%, 100% 0%);
  height: 100px;
  position: relative;
  transform-origin: 50% 66.66%;
  width: 100px;
}

svg {
  display: block;
}

g {
  position: relative;
}

.triangle {
  fill: #00ff00;
  stroke: #00ff00;
}

#triangle, #triangle-2 {
  height: 100%;
  transform-origin: 50% 66.66%;
  width: 100%;
}

#triangle {
  left: 0;
  position: absolute;
  top: 0;
}

.fill {
  fill: rgb(255, 255, 255);
  stroke: rgb(255, 255, 255);
}

#fill-1-1, #fill-1-2 {
  transform-origin: 22.5px 209.1px;
}
#fill-2-1, #fill-2-2 {
  transform-origin: 67.6px 209.1px;
}
#fill-3-1, #fill-3-2 {
  transform-origin: 45.07px 199.1px;
}
#fill-4-1, #fill-4-2 {
  transform-origin: 90.7px 199.1px;
}
#fill-5-1, #fill-5-2 {
  transform-origin: 112.6px 209.1px;
}
#fill-6-1, #fill-6-2 {
  transform-origin: 135.07px 199.1px;
}
#fill-7-1, #fill-7-2 {
  transform-origin: 45.07px 165.1px;
}
#fill-8-1, #fill-8-2 {
  transform-origin: 157.6px 209.1px;
}
#fill-9-1, #fill-9-2 {
  transform-origin: 180.07px 199.1px;
}
#fill-10-1, #fill-10-2 {
  transform-origin: 202.6px 209.1px;
}
#fill-11-1, #fill-11-2 {
  transform-origin: 67.6px 155.1px;
}
#fill-12-1, #fill-12-2 {
  transform-origin: 180.07px 165.1px;
}
#fill-13-1, #fill-13-2 {
  transform-origin: 67.6px 122.1px;
}
#fill-14-1, #fill-14-2 {
  transform-origin: 157.6px 155.1px;
}
#fill-15-1, #fill-15-2 {
  transform-origin: 157.6px 122.1px;
}
#fill-16-1-1, #fill-16-1-2 {
  transform-origin: 90.07px 108.1px;
}
#fill-16-2-1, #fill-16-2-2 {
  transform-origin: 135.07px 108.1px;
}
#fill-17-1, #fill-17-2 {
  transform-origin: 135.07px 76.1px;
}
#fill-18-1, #fill-18-2 {
  transform-origin: 90.07px 76.1px;
}
#fill-19-1, #fill-19-2 {
  transform-origin: 112.6px 62.1px;
}
#fill-20-1, #fill-20-2 {
  transform-origin: 112.6px 30.1px;
}
</style>
<script setup>
import { onMounted } from 'vue';
import { defineComponent } from 'vue';
// eslint-disable-next-line
const component = defineComponent({
  name: 'ezyLoading', 
});
onMounted(() => {

  const durationFraction = 0.5;
const duration = 92/30 * 1000;
const durationRotate = 288/30 * 1000;
const insetDelay = 0.50;
const offset = 12/96;
const offsetScale = 30/96;

document.querySelector('#loading').animate([
  { transform: 'rotate(0)' },
  { transform: 'rotate(360deg)' }
], { 
  duration: durationRotate,
  iterations: Infinity
});

document.querySelector('#triangle').animate([
  { transform: 'scale(1)' },
  { transform: 'scale(0.4)' },
  { transform: 'scale(0.16)' }
], { 
  duration: duration,
  iterations: Infinity,
  iterationStart: insetDelay
});

document.querySelector('#triangle-2').animate([
  { transform: 'scale(1)' },
  { transform: 'scale(0.4)' },
  { transform: 'scale(0.16)' }
], { 
  duration: duration,
  iterations: Infinity,
});

    
document.querySelector('#triangle-1-1').animate([
  { opacity: '0' }, 
  { opacity: '1', offset: offset },
  { opacity: '1' }
], { 
  duration: duration,
  iterations: Infinity,
  iterationStart: insetDelay
});

document.querySelector('#fill-1-1').animate([
  { transform: 'scale(1)' },
  { transform: 'scale(0)', offset: offsetScale },
  { transform: 'scale(0)' }
], { 
  duration: duration,
  iterations: Infinity,
  iterationStart: insetDelay
});

document.querySelector('#triangle-2-1').animate([
  { opacity: '0' }, 
  { opacity: '0', offset: (1/19) * durationFraction },
  { opacity: '1', offset: offset + ((1/19) * durationFraction ) },
  { opacity: '1' }
], {
  duration: duration,
  iterations: Infinity,
  iterationStart: insetDelay
});

document.querySelector('#fill-2-1').animate([
  { transform: 'scale(1)' },
  { transform: 'scale(1)', offset: (1/19) * durationFraction },
  { transform: 'scale(0)', offset: offsetScale + ((1/19) * durationFraction ) },
  { transform: 'scale(0)' }
], {
  duration: duration,
  iterations: Infinity,
  iterationStart: insetDelay
});

document.querySelector('#triangle-3-1').animate([
  { opacity: '0' },
  { opacity: '0', offset: (2/19) * durationFraction },
  { opacity: '1', offset: offset + ((2/19) * durationFraction ) },
  { opacity: '1' }
], {
  duration: duration,
  iterations: Infinity,
  iterationStart: insetDelay
});

document.querySelector('#fill-3-1').animate([
  { transform: 'scale(1)' },
  { transform: 'scale(1)', offset: (2/19) * durationFraction },
  { transform: 'scale(0)', offset: offsetScale + ((2/19) * durationFraction ) },
  { transform: 'scale(0)' }
], {
  duration: duration,
  iterations: Infinity,
  iterationStart: insetDelay
});

document.querySelector('#triangle-4-1').animate([
  { opacity: '0' },
  { opacity: '0', offset: (3/19) * durationFraction },
  { opacity: '1', offset: offset + ((3/19) * durationFraction ) },
  { opacity: '1' }
], {
  duration: duration,
  iterations: Infinity,
  iterationStart: insetDelay
});

document.querySelector('#fill-4-1').animate([
  { transform: 'scale(1)' },
  { transform: 'scale(1)', offset: (3/19) * durationFraction },
  { transform: 'scale(0)', offset: offsetScale + ((3/19) * durationFraction ) },
  { transform: 'scale(0)' }
], {
  duration: duration,
  iterations: Infinity,
  iterationStart: insetDelay
});

document.querySelector('#triangle-5-1').animate([
  { opacity: '0' },
  { opacity: '0', offset: (4/19) * durationFraction },
  { opacity: '1', offset: offset + ((4/19) * durationFraction ) },
  { opacity: '1' }
], {
  duration: duration,
  iterations: Infinity,
  iterationStart: insetDelay
});

document.querySelector('#fill-5-1').animate([
  { transform: 'scale(1)' },
  { transform: 'scale(1)', offset: (4/19) * durationFraction },
  { transform: 'scale(0)', offset: offsetScale + ((4/19) * durationFraction ) },
  { transform: 'scale(0)' }
], {
    duration: duration,
    iterations: Infinity,
    iterationStart: insetDelay
});

document.querySelector('#triangle-6-1').animate([
    { opacity: '0' },
    { opacity: '0', offset: (5/19) * durationFraction },
    { opacity: '1', offset: offset + ((5/19) * durationFraction ) },
    { opacity: '1' }
], {
    duration: duration,
    iterations: Infinity,
    iterationStart: insetDelay
});

document.querySelector('#fill-6-1').animate([
    { transform: 'scale(1)' },
    { transform: 'scale(1)', offset: (5/19) * durationFraction },
    { transform: 'scale(0)', offset: offsetScale + ((5/19) * durationFraction ) },
    { transform: 'scale(0)' }
], {
    duration: duration,
    iterations: Infinity,
    iterationStart: insetDelay
});

document.querySelector('#triangle-7-1').animate([
    { opacity: '0' },
    { opacity: '0', offset: (6/19) * durationFraction },
    { opacity: '1', offset: offset + ((6/19) * durationFraction ) },
    { opacity: '1' }
], {
    duration: duration,
    iterations: Infinity,
    iterationStart: insetDelay
});

document.querySelector('#fill-7-1').animate([
    { transform: 'scale(1)' },
    { transform: 'scale(1)', offset: (6/19) * durationFraction },
    { transform: 'scale(0)', offset: offsetScale + ((6/19) * durationFraction ) },
    { transform: 'scale(0)' }
], {
    duration: duration,
    iterations: Infinity,
    iterationStart: insetDelay
});

document.querySelector('#triangle-8-1').animate([
    { opacity: '0' },
    { opacity: '0', offset: (7/19) * durationFraction },
    { opacity: '1', offset: offset + ((7/19) * durationFraction ) },
    { opacity: '1' }
], {
    duration: duration,
    iterations: Infinity,
    iterationStart: insetDelay
});

document.querySelector('#fill-8-1').animate([
    { transform: 'scale(1)' },
    { transform: 'scale(1)', offset: (7/19) * durationFraction },
    { transform: 'scale(0)', offset: offsetScale + ((7/19) * durationFraction ) },
    { transform: 'scale(0)' }
], {
    duration: duration,
    iterations: Infinity,
    iterationStart: insetDelay
});

document.querySelector('#triangle-9-1').animate([
    { opacity: '0' },
    { opacity: '0', offset: (8/19) * durationFraction },
    { opacity: '1', offset: offset + ((8/19) * durationFraction ) },
    { opacity: '1' }
], {
    duration: duration,
    iterations: Infinity,
    iterationStart: insetDelay
});

document.querySelector('#fill-9-1').animate([
    { transform: 'scale(1)' },
    { transform: 'scale(1)', offset: (8/19) * durationFraction },
    { transform: 'scale(0)', offset: offsetScale + ((8/19) * durationFraction ) },
    { transform: 'scale(0)' }
], {
    duration: duration,
    iterations: Infinity,
    iterationStart: insetDelay
});

document.querySelector('#triangle-10-1').animate([
    { opacity: '0' },
    { opacity: '0', offset: (9/19) * durationFraction },
    { opacity: '1', offset: offset + ((9/19) * durationFraction ) },
    { opacity: '1' }
], {
    duration: duration,
    iterations: Infinity,
    iterationStart: insetDelay
});

document.querySelector('#fill-10-1').animate([
    { transform: 'scale(1)' },
    { transform: 'scale(1)', offset: (9/19) * durationFraction },
    { transform: 'scale(0)', offset: offsetScale + ((9/19) * durationFraction ) },
    { transform: 'scale(0)' }
], {
    duration: duration,
    iterations: Infinity,
    iterationStart: insetDelay
});

document.querySelector('#triangle-11-1').animate([
    { opacity: '0' },
    { opacity: '0', offset: (10/19) * durationFraction },
    { opacity: '1', offset: offset + ((10/19) * durationFraction )},
    { opacity: '1' }
], {
    duration: duration,
    iterations: Infinity,
    iterationStart: insetDelay
});

document.querySelector('#fill-11-1').animate([
    { transform: 'scale(1)' },
    { transform: 'scale(1)', offset: (10/19) * durationFraction },
    { transform: 'scale(0)', offset: offsetScale  + ((10/19) * durationFraction ) },
    { transform: 'scale(0)' }
], {
    duration: duration,
    iterations: Infinity,
    iterationStart: insetDelay
});

document.querySelector('#triangle-12-1').animate([
    { opacity: '0' },
    { opacity: '0', offset: (11/19) * durationFraction },
    { opacity: '1', offset: offset + ((11/19) * durationFraction ) },
    { opacity: '1' }
], {
    duration: duration,
    iterations: Infinity,
    iterationStart: insetDelay
});

document.querySelector('#fill-12-1').animate([
    { transform: 'scale(1)' },
    { transform: 'scale(1)', offset: (11/19) * durationFraction },
    { transform: 'scale(0)', offset: offsetScale + ((11/19) * durationFraction ) },
    { transform: 'scale(0)' }
], {
    duration: duration,
    iterations: Infinity,
    iterationStart: insetDelay
});

document.querySelector('#triangle-13-1').animate([
    { opacity: '0' },
    { opacity: '0', offset: (12/19) * durationFraction },
    { opacity: '1', offset: offset + ((12/19) * durationFraction ) },
    { opacity: '1' }
], {
    duration: duration,
    iterations: Infinity,
    iterationStart: insetDelay
});

document.querySelector('#fill-13-1').animate([
    { transform: 'scale(1)' },
    { transform: 'scale(1)', offset: (12/19) * durationFraction },
    { transform: 'scale(0)', offset: offsetScale + ((12/19) * durationFraction ) },
    { transform: 'scale(0)' }
], {
    duration: duration,
    iterations: Infinity,
    iterationStart: insetDelay
});

document.querySelector('#triangle-14-1').animate([
    { opacity: '0' },
    { opacity: '0', offset: (13/19) * durationFraction },
    { opacity: '1', offset: offset + ((13/19) * durationFraction ) },
    { opacity: '1' }
], {
    duration: duration,
    iterations: Infinity,
    iterationStart: insetDelay
});

document.querySelector('#fill-14-1').animate([
    { transform: 'scale(1)' },
    { transform: 'scale(1)', offset: (13/19) * durationFraction },
    { transform: 'scale(0)', offset: offsetScale + ((13/19) * durationFraction ) },
    { transform: 'scale(0)' }
], {
    duration: duration,
    iterations: Infinity,
    iterationStart: insetDelay
});

document.querySelector('#triangle-15-1').animate([
    { opacity: '0' },
    { opacity: '0', offset: (14/19) * durationFraction },
    { opacity: '1', offset: offset + ((14/19) * durationFraction ) },
    { opacity: '1' }
], {
    duration: duration,
    iterations: Infinity,
    iterationStart: insetDelay
});

document.querySelector('#fill-15-1').animate([
    { transform: 'scale(1)' },
    { transform: 'scale(1)', offset: (14/19) * durationFraction },
    { transform: 'scale(0)', offset: offsetScale + ((14/19) * durationFraction ) },
    { transform: 'scale(0)' }
], {
    duration: duration,
    iterations: Infinity,
    iterationStart: insetDelay
});

document.querySelector('#triangle-16-1-1').animate([
    { opacity: '0' },
    { opacity: '0', offset: (15/19) * durationFraction },
    { opacity: '1', offset: offset + ((15/19) * durationFraction )},
    { opacity: '1' }
], {
    duration: duration,
    iterations: Infinity,
    iterationStart: insetDelay
});

document.querySelector('#fill-16-1-1').animate([
    { transform: 'scale(1)' },
    { transform: 'scale(1)', offset: (15/19) * durationFraction },
    { transform: 'scale(0)', offset: offsetScale + ((15/19) * durationFraction ) },
    { transform: 'scale(0)' }
], {
    duration: duration,
    iterations: Infinity,
    iterationStart: insetDelay
});

document.querySelector('#triangle-16-2-1').animate([
    { opacity: '0' },
    { opacity: '0', offset: (15/19) * durationFraction },
    { opacity: '1', offset: offset + ((15/19) * durationFraction ) },
    { opacity: '1' }
], {
    duration: duration,
    iterations: Infinity,
    iterationStart: insetDelay
});

document.querySelector('#fill-16-2-1').animate([
    { transform: 'scale(1)' },
    { transform: 'scale(1)', offset: (15/19) * durationFraction },
    { transform: 'scale(0)', offset: offsetScale + ((15/19) * durationFraction ) },
    { transform: 'scale(0)' }
], {
    duration: duration,
    iterations: Infinity,
    iterationStart: insetDelay
});

document.querySelector('#triangle-17-1').animate([
    { opacity: '0' },
    { opacity: '0', offset: (16/19) * durationFraction },
    { opacity: '1', offset: offset + ((16/19) * durationFraction ) },
    { opacity: '1' }
], {
    duration: duration,
    iterations: Infinity,
    iterationStart: insetDelay
});

document.querySelector('#fill-17-1').animate([
    { transform: 'scale(1)' },
    { transform: 'scale(1)', offset: (16/19) * durationFraction },
    { transform: 'scale(0)', offset: offsetScale + ((16/19) * durationFraction ) },
    { transform: 'scale(0)' }
], {
    duration: duration,
    iterations: Infinity,
    iterationStart: insetDelay
});

document.querySelector('#triangle-18-1').animate([
    { opacity: '0' },
    { opacity: '0', offset: (17/19) * durationFraction },
    { opacity: '1', offset: offset + ((17/19) * durationFraction ) },
    { opacity: '1' }
], {
    duration: duration,
    iterations: Infinity,
    iterationStart: insetDelay
});

document.querySelector('#fill-18-1').animate([
    { transform: 'scale(1)' },
    { transform: 'scale(1)', offset: (17/19) * durationFraction },
    { transform: 'scale(0)', offset: offsetScale + ((17/19) * durationFraction ) },
    { transform: 'scale(0)' }
], {
    duration: duration,
    iterations: Infinity,
    iterationStart: insetDelay
});

document.querySelector('#triangle-19-1').animate([
    { opacity: '0' },
    { opacity: '0', offset: (18/19) * durationFraction },
    { opacity: '1', offset: offset + ((18/19) * durationFraction ) },
    { opacity: '1' }
], {
    duration: duration,
    iterations: Infinity,
    iterationStart: insetDelay
});

document.querySelector('#fill-19-1').animate([
    { transform: 'scale(1)' },
    { transform: 'scale(1)', offset: (18/19) * durationFraction },
    { transform: 'scale(0)', offset: offsetScale + ((18/19) * durationFraction ) },
    { transform: 'scale(0)' }
], {
    duration: duration,
    iterations: Infinity,
    iterationStart: insetDelay
});

document.querySelector('#triangle-20-1').animate([
    { opacity: '0' },
    { opacity: '0', offset: (19/19) * durationFraction },
    { opacity: '1', offset: offset + ((19/19) * durationFraction ) },
    { opacity: '1' }
], {
    duration: duration,
    iterations: Infinity,
    iterationStart: insetDelay
});

document.querySelector('#fill-20-1').animate([
    { transform: 'scale(1)' },
    { transform: 'scale(1)', offset: (19/19) * durationFraction },
    { transform: 'scale(0)', offset: offsetScale + ((19/19) * durationFraction ) },
    { transform: 'scale(0)' }
], {
    duration: duration,
    iterations: Infinity,
    iterationStart: insetDelay
});

/*  Triangle #2  */

document.querySelector('#triangle-1-2').animate([
    { opacity: '0' }, 
    { opacity: '1', offset: offset },
    { opacity: '1' }
], { 
    duration: duration,
    iterations: Infinity
});

document.querySelector('#fill-1-2').animate([
    { transform: 'scale(1)' },
    { transform: 'scale(0)', offset: offsetScale },
    { transform: 'scale(0)' }
], { 
    duration: duration,
    iterations: Infinity
});

document.querySelector('#triangle-2-2').animate([
    { opacity: '0' }, 
    { opacity: '0', offset: (1/19) * durationFraction },
    { opacity: '1', offset: offset + ((1/19) * durationFraction ) },
    { opacity: '1' }
], {
    duration: duration,
    iterations: Infinity
});

document.querySelector('#fill-2-2').animate([
    { transform: 'scale(1)' },
    { transform: 'scale(1)', offset: (1/19) * durationFraction },
    { transform: 'scale(0)', offset: offsetScale + ((1/19) * durationFraction ) },
    { transform: 'scale(0)' }
], {
    duration: duration,
    iterations: Infinity
});

document.querySelector('#triangle-3-2').animate([
    { opacity: '0' },
    { opacity: '0', offset: (2/19) * durationFraction },
    { opacity: '1', offset: offset + ((2/19) * durationFraction ) },
    { opacity: '1' }
], {
    duration: duration,
    iterations: Infinity
});

document.querySelector('#fill-3-2').animate([
    { transform: 'scale(1)' },
    { transform: 'scale(1)', offset: (2/19) * durationFraction },
    { transform: 'scale(0)', offset: offsetScale + ((2/19) * durationFraction ) },
    { transform: 'scale(0)' }
], {
    duration: duration,
    iterations: Infinity
});

document.querySelector('#triangle-4-2').animate([
    { opacity: '0' },
    { opacity: '0', offset: (3/19) * durationFraction },
    { opacity: '1', offset: offset + ((3/19) * durationFraction ) },
    { opacity: '1' }
], {
    duration: duration,
    iterations: Infinity
});

document.querySelector('#fill-4-2').animate([
    { transform: 'scale(1)' },
    { transform: 'scale(1)', offset: (3/19) * durationFraction },
    { transform: 'scale(0)', offset: offsetScale + ((3/19) * durationFraction ) },
    { transform: 'scale(0)' }
], {
    duration: duration,
    iterations: Infinity
});

document.querySelector('#triangle-5-2').animate([
    { opacity: '0' },
    { opacity: '0', offset: (4/19) * durationFraction },
    { opacity: '1', offset: offset + ((4/19) * durationFraction ) },
    { opacity: '1' }
], {
    duration: duration,
    iterations: Infinity
});

document.querySelector('#fill-5-2').animate([
    { transform: 'scale(1)' },
    { transform: 'scale(1)', offset: (4/19) * durationFraction },
    { transform: 'scale(0)', offset: offsetScale + ((4/19) * durationFraction ) },
    { transform: 'scale(0)' }
], {
    duration: duration,
    iterations: Infinity
});

document.querySelector('#triangle-6-2').animate([
    { opacity: '0' },
    { opacity: '0', offset: (5/19) * durationFraction },
    { opacity: '1', offset: offset + ((5/19) * durationFraction ) },
    { opacity: '1' }
], {
    duration: duration,
    iterations: Infinity
});

document.querySelector('#fill-6-2').animate([
    { transform: 'scale(1)' },
    { transform: 'scale(1)', offset: (5/19) * durationFraction },
    { transform: 'scale(0)', offset: offsetScale + ((5/19) * durationFraction ) },
    { transform: 'scale(0)' }
], {
    duration: duration,
    iterations: Infinity
});

document.querySelector('#triangle-7-2').animate([
    { opacity: '0' },
    { opacity: '0', offset: (6/19) * durationFraction },
    { opacity: '1', offset: offset + ((6/19) * durationFraction ) },
    { opacity: '1' }
], {
    duration: duration,
    iterations: Infinity
});

document.querySelector('#fill-7-2').animate([
    { transform: 'scale(1)' },
    { transform: 'scale(1)', offset: (6/19) * durationFraction },
    { transform: 'scale(0)', offset: offsetScale + ((6/19) * durationFraction ) },
    { transform: 'scale(0)' }
], {
    duration: duration,
    iterations: Infinity
});

document.querySelector('#triangle-8-2').animate([
    { opacity: '0' },
    { opacity: '0', offset: (7/19) * durationFraction },
    { opacity: '1', offset: offset + ((7/19) * durationFraction ) },
    { opacity: '1' }
], {
    duration: duration,
    iterations: Infinity
});

document.querySelector('#fill-8-2').animate([
    { transform: 'scale(1)' },
    { transform: 'scale(1)', offset: (7/19) * durationFraction },
    { transform: 'scale(0)', offset: offsetScale + ((7/19) * durationFraction ) },
    { transform: 'scale(0)' }
], {
    duration: duration,
    iterations: Infinity
});

document.querySelector('#triangle-9-2').animate([
    { opacity: '0' },
    { opacity: '0', offset: (8/19) * durationFraction },
    { opacity: '1', offset: offset + ((8/19) * durationFraction ) },
    { opacity: '1' }
], {
    duration: duration,
    iterations: Infinity
});

document.querySelector('#fill-9-2').animate([
    { transform: 'scale(1)' },
    { transform: 'scale(1)', offset: (8/19) * durationFraction },
    { transform: 'scale(0)', offset: offsetScale + ((8/19) * durationFraction ) },
    { transform: 'scale(0)' }
], {
    duration: duration,
    iterations: Infinity
});

document.querySelector('#triangle-10-2').animate([
    { opacity: '0' },
    { opacity: '0', offset: (9/19) * durationFraction },
    { opacity: '1', offset: offset + ((9/19) * durationFraction ) },
    { opacity: '1' }
], {
    duration: duration,
    iterations: Infinity
});

document.querySelector('#fill-10-2').animate([
    { transform: 'scale(1)' },
    { transform: 'scale(1)', offset: (9/19) * durationFraction },
    { transform: 'scale(0)', offset: offsetScale + ((9/19) * durationFraction ) },
    { transform: 'scale(0)' }
], {
    duration: duration,
    iterations: Infinity
});

document.querySelector('#triangle-11-2').animate([
    { opacity: '0' },
    { opacity: '0', offset: (10/19) * durationFraction },
    { opacity: '1', offset: offset + ((10/19) * durationFraction )},
    { opacity: '1' }
], {
    duration: duration,
    iterations: Infinity
});

document.querySelector('#fill-11-2').animate([
    { transform: 'scale(1)' },
    { transform: 'scale(1)', offset: (10/19) * durationFraction },
    { transform: 'scale(0)', offset: offsetScale  + ((10/19) * durationFraction ) },
    { transform: 'scale(0)' }
], {
    duration: duration,
    iterations: Infinity
});

document.querySelector('#triangle-12-2').animate([
    { opacity: '0' },
    { opacity: '0', offset: (11/19) * durationFraction },
    { opacity: '1', offset: offset + ((11/19) * durationFraction ) },
    { opacity: '1' }
], {
    duration: duration,
    iterations: Infinity
});

document.querySelector('#fill-12-2').animate([
    { transform: 'scale(1)' },
    { transform: 'scale(1)', offset: (11/19) * durationFraction },
    { transform: 'scale(0)', offset: offsetScale + ((11/19) * durationFraction ) },
    { transform: 'scale(0)' }
], {
    duration: duration,
    iterations: Infinity
});

document.querySelector('#triangle-13-2').animate([
    { opacity: '0' },
    { opacity: '0', offset: (12/19) * durationFraction },
    { opacity: '1', offset: offset + ((12/19) * durationFraction ) },
    { opacity: '1' }
], {
    duration: duration,
    iterations: Infinity
});

document.querySelector('#fill-13-2').animate([
    { transform: 'scale(1)' },
    { transform: 'scale(1)', offset: (12/19) * durationFraction },
    { transform: 'scale(0)', offset: offsetScale + ((12/19) * durationFraction ) },
    { transform: 'scale(0)' }
], {
    duration: duration,
    iterations: Infinity
});

document.querySelector('#triangle-14-2').animate([
    { opacity: '0' },
    { opacity: '0', offset: (13/19) * durationFraction },
    { opacity: '1', offset: offset + ((13/19) * durationFraction ) },
    { opacity: '1' }
], {
    duration: duration,
    iterations: Infinity
});

document.querySelector('#fill-14-2').animate([
    { transform: 'scale(1)' },
    { transform: 'scale(1)', offset: (13/19) * durationFraction },
    { transform: 'scale(0)', offset: offsetScale + ((13/19) * durationFraction ) },
    { transform: 'scale(0)' }
], {
    duration: duration,
    iterations: Infinity
});

document.querySelector('#triangle-15-2').animate([
    { opacity: '0' },
    { opacity: '0', offset: (14/19) * durationFraction },
    { opacity: '1', offset: offset + ((14/19) * durationFraction ) },
    { opacity: '1' }
], {
    duration: duration,
    iterations: Infinity
});

document.querySelector('#fill-15-2').animate([
    { transform: 'scale(1)' },
    { transform: 'scale(1)', offset: (14/19) * durationFraction },
    { transform: 'scale(0)', offset: offsetScale + ((14/19) * durationFraction ) },
    { transform: 'scale(0)' }
], {
    duration: duration,
    iterations: Infinity
});

document.querySelector('#triangle-16-1-2').animate([
    { opacity: '0' },
    { opacity: '0', offset: (15/19) * durationFraction },
    { opacity: '1', offset: offset + ((15/19) * durationFraction )},
    { opacity: '1' }
], {
    duration: duration,
    iterations: Infinity
});

document.querySelector('#fill-16-1-2').animate([
    { transform: 'scale(1)' },
    { transform: 'scale(1)', offset: (15/19) * durationFraction },
    { transform: 'scale(0)', offset: offsetScale + ((15/19) * durationFraction ) },
    { transform: 'scale(0)' }
], {
    duration: duration,
    iterations: Infinity
});

document.querySelector('#triangle-16-2-2').animate([
    { opacity: '0' },
    { opacity: '0', offset: (15/19) * durationFraction },
    { opacity: '1', offset: offset + ((15/19) * durationFraction ) },
    { opacity: '1' }
], {
    duration: duration,
    iterations: Infinity
});

document.querySelector('#fill-16-2-2').animate([
    { transform: 'scale(1)' },
    { transform: 'scale(1)', offset: (15/19) * durationFraction },
    { transform: 'scale(0)', offset: offsetScale + ((15/19) * durationFraction ) },
    { transform: 'scale(0)' }
], {
    duration: duration,
    iterations: Infinity
});

document.querySelector('#triangle-17-2').animate([
    { opacity: '0' },
    { opacity: '0', offset: (16/19) * durationFraction },
    { opacity: '1', offset: offset + ((16/19) * durationFraction ) },
    { opacity: '1' }
], {
    duration: duration,
    iterations: Infinity
});

document.querySelector('#fill-17-2').animate([
    { transform: 'scale(1)' },
    { transform: 'scale(1)', offset: (16/19) * durationFraction },
    { transform: 'scale(0)', offset: offsetScale + ((16/19) * durationFraction ) },
    { transform: 'scale(0)' }
], {
    duration: duration,
    iterations: Infinity
});

document.querySelector('#triangle-18-2').animate([
    { opacity: '0' },
    { opacity: '0', offset: (17/19) * durationFraction },
    { opacity: '1', offset: offset + ((17/19) * durationFraction ) },
    { opacity: '1' }
], {
    duration: duration,
    iterations: Infinity
});

document.querySelector('#fill-18-2').animate([
    { transform: 'scale(1)' },
    { transform: 'scale(1)', offset: (17/19) * durationFraction },
    { transform: 'scale(0)', offset: offsetScale + ((17/19) * durationFraction ) },
    { transform: 'scale(0)' }
], {
    duration: duration,
    iterations: Infinity
});

document.querySelector('#triangle-19-2').animate([
    { opacity: '0' },
    { opacity: '0', offset: (18/19) * durationFraction },
    { opacity: '1', offset: offset + ((18/19) * durationFraction ) },
    { opacity: '1' }
], {
    duration: duration,
    iterations: Infinity
});

document.querySelector('#fill-19-2').animate([
    { transform: 'scale(1)' },
    { transform: 'scale(1)', offset: (18/19) * durationFraction },
    { transform: 'scale(0)', offset: offsetScale + ((18/19) * durationFraction ) },
    { transform: 'scale(0)' }
], {
    duration: duration,
    iterations: Infinity
});

document.querySelector('#triangle-20-2').animate([
    { opacity: '0' },
    { opacity: '0', offset: (19/19) * durationFraction },
    { opacity: '1', offset: offset + ((19/19) * durationFraction ) },
    { opacity: '1' }
], {
    duration: duration,
    iterations: Infinity
});

document.querySelector('#fill-20-2').animate([
    { transform: 'scale(1)' },
    { transform: 'scale(1)', offset: (19/19) * durationFraction },
    { transform: 'scale(0)', offset: offsetScale + ((19/19) * durationFraction ) },
    { transform: 'scale(0)' }
], {
    duration: duration,
    iterations: Infinity
});
});
</script>
