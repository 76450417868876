<template>
  <div class="customer-list" component="customerList">
    <div class="customer-list-modal">
      <div class="customer-list-modal-header">
        <v-icon @click="$emit('close')">mdi-close-circle-outline</v-icon>
        <div class="customer-list-modal-header-title">انتخاب پروفایل</div>
      </div>
      <div class="customer-list-modal-content">
        <div class="customer-list-modal-content-header">
          <div class="customer-list-modal-content-header-title">اطلاعات</div>
          <div class="customer-list-modal-content-header-title">شماره تماس</div>
          <div class="customer-list-modal-content-header-title">نام مشتری</div>
        </div>
        <div class="divider"></div>
        <div
          class="customer-list-modal-content-list"
          v-for="(customer, index) in customers"
          :key="index"
        >
          <div class="customer-list-modal-content-list-items">
            <div class="customer-list-modal-content-list-items-detail">
              <div
                @click="customerModal(customer[0].id)"
                class="customer-list-modal-content-list-items-detail-icon"
              ></div>
            </div>
            <a
              :href="`tel:${customer[0].value}`"
              class="customer-list-modal-content-list-items-number"
            >
              {{ customer[0].value }}
            </a>
            <div class="customer-list-modal-content-list-items-name">
              {{ customer[1].value }}
            </div>
          </div>
          <div class="divider"></div>
        </div>
      </div>
    </div>
    <selectedCustomerModal
      :profile_id="this.profile_id"
      v-if="showCustomerModal"
      @close="showCustomerInfo"
      :customer="this.selectedCustomer"
    />
  </div>
</template>
<style lang="scss" scoped>
.customer-list {
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(128, 128, 128, 0.5215686275);
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: end;
  z-index: 2;
  &-modal {
    max-width: 600px;
    width: 100%;
    background: white;
    height: 90vh;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    ::-webkit-scrollbar {
      width: 0 !important;
      height: 0 !important;
    }
    &-header {
      display: flex;
      flex-direction: row-reverse;
      padding: 16px;
      box-shadow: 0px 1px 5px 0px #939393;
      position: sticky;
      top: 0;
      z-index: 10;
      background: white;
      i {
      }
      &-title {
        flex: 1;
        text-align: center;
        padding-left: 24px;
      }
    }
    &-content {
      padding-top: 56px;
      padding-bottom: 40px;
      &-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 12px 24px;
        &-title {
          width: 100%;
          text-align: center;
        }
      }
      .divider {
        background-image: url('data:image/svg+xml,<svg width="375" height="1" viewBox="0 0 375 1" fill="none" xmlns="http://www.w3.org/2000/svg"><line x1="3.83882e-08" y1="0.56089" x2="375" y2="0.560923" stroke="url(%23paint0_linear_985_3898)" stroke-opacity="0.58" stroke-width="0.87822"/><defs><linearGradient id="paint0_linear_985_3898" x1="-4.37114e-08" y1="1.5" x2="375" y2="1.50003" gradientUnits="userSpaceOnUse"><stop stop-color="%239F9F9F" stop-opacity="0"/><stop offset="0.505" stop-color="%236F6F6F"/><stop offset="1" stop-color="%23393939" stop-opacity="0"/></linearGradient></defs></svg>');
        height: 10px;
        width: 100%;
        background-size: 100% 10px;
      }
      &-list {
        &-items {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          padding: 12px 24px;
          &-detail {
            width: 100%;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            &-icon {
              cursor: pointer;
              background-image: url('data:image/svg+xml,<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1.5 6C1.5 5.58579 1.83579 5.25 2.25 5.25H15.75C16.1642 5.25 16.5 5.58579 16.5 6C16.5 6.41421 16.1642 6.75 15.75 6.75H2.25C1.83579 6.75 1.5 6.41421 1.5 6Z" fill="black"/><path d="M1.5 9C1.5 8.58578 1.83579 8.25 2.25 8.25H15.75C16.1642 8.25 16.5 8.58578 16.5 9C16.5 9.41422 16.1642 9.75 15.75 9.75H2.25C1.83579 9.75 1.5 9.41422 1.5 9Z" fill="black"/><path d="M2.25 11.25C1.83579 11.25 1.5 11.5858 1.5 12C1.5 12.4142 1.83579 12.75 2.25 12.75H11.25C11.6642 12.75 12 12.4142 12 12C12 11.5858 11.6642 11.25 11.25 11.25H2.25Z" fill="black"/></svg>');
              width: 18px;
              height: 18px;
            }
          }
          &-number {
            width: 100%;
            text-align: center;
            text-decoration: none;
            color: #0075ff;
          }
          &-name {
            width: 100%;
            text-align: center;
          }
        }
        .divider {
          background-image: url('data:image/svg+xml,<svg width="375" height="1" viewBox="0 0 375 1" fill="none" xmlns="http://www.w3.org/2000/svg"><line x1="3.83882e-08" y1="0.56089" x2="375" y2="0.560923" stroke="url(%23paint0_linear_985_3898)" stroke-opacity="0.58" stroke-width="0.87822"/><defs><linearGradient id="paint0_linear_985_3898" x1="-4.37114e-08" y1="1.5" x2="375" y2="1.50003" gradientUnits="userSpaceOnUse"><stop stop-color="%239F9F9F" stop-opacity="0"/><stop offset="0.505" stop-color="%236F6F6F"/><stop offset="1" stop-color="%23393939" stop-opacity="0"/></linearGradient></defs></svg>');
          height: 10px;
          width: 100%;
          background-size: 100% 10px;
        }
      }
    }
  }
}
</style>
<script>
import selectedCustomerModal from "./selectedCustomerModal.vue";
export default {
  name: "customerList",
  data: () => ({
    selectedCustomer: [],
    showCustomerModal: false,
  }),
  components: {
    selectedCustomerModal,
  },
  methods: {
    customerModal(val) {
      this.selectedCustomer = val;
      this.showCustomerInfo();
    },
    showCustomerInfo() {
      this.showCustomerModal = !this.showCustomerModal;
    },
  },
  props: {
    customers: Object,
    profile_id: Number,
  },
};
</script>
