<template>
  <div class="account-page" :style="slideMenuStyle" component="accountPage">
    <div class="account-page-header">
      <v-icon class="account-page-header-menu" @click="showSlideMenu">mdi-menu</v-icon>
      <div class="account-page-header-title">EZY CONNECT</div>
    </div>
    <div class="account-page-content">
      <div class="account-page-content-image">
        <img
          :src="filteredImage[0].image"
          alt=""
          class="account-page-content-image-file"
        />
      </div>
      <div class="account-page-content-buttons">
        <button
          v-for="(tab, index) in tabs"
          :key="tab.class"
          @click="changeTab(index)"
          :class="
            tab.status
              ? `active account-page-content-buttons-` + tab.class
              : `account-page-content-buttons-` + tab.class
          "
        >
          {{ tab.name }}
        </button>
      </div>
      <div class="account-page-content-page">
        <div class="account-page-content-page-content">
          <div
            v-if="tabs[0].status == true"
            class="account-page-content-page-content-subscription"
          >
            <subscriptionBlock />
          </div>
          <div
            v-if="tabs[1].status == true"
            class="account-page-content-page-content-information"
          >
            <profileInformationBlock />
          </div>
          <div
            v-if="tabs[2].status == true"
            class="account-page-content-page-content-customerClub"
          >
            <customerClubForm :profiles="this.profileData" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss">
@import "./styles/index.scss";
</style>
<script>
/* eslint-disable */
import subscriptionBlock from "@/components/subscriptionBlock/index.vue";
import photoSwiper from "@/components/photoSwiper/index.vue";
import profileInformationBlock from "@/components/profileInformationBlock/index.vue";
import customerClubForm from "@/components/customerClubForm/index.vue";

import axios from "axios";
import dashboardConfig from "@/services/dashboard.js";
import router from "@/router";
export default {
  name: "accountPage",
  components: {
    subscriptionBlock,
    photoSwiper,
    profileInformationBlock,
    customerClubForm,
  },
  data: () => ({
    tabs: [
      {
        name: "اشتراک",
        status: true,
        image: "./assets/images/content/Subscription.png",
        class: "subscription",
      },
      {
        name: "اطلاعات حساب",
        status: false,
        image: "./assets/images/content/account-information.png",
        class: "information",
      },
      {
        name: "باشگاه مشتریان",
        status: false,
        image: "./assets/images/content/customer-club.png",
        class: "CustomerClub",
      },
    ],
    profileData: [],
    isSlideMenuVisible: false,
  }),
  mounted() {
    const uuid = localStorage.getItem("uuid");
    if (!uuid || uuid.length === 0) {
      router.replace("/");
    } else {
      const data = new FormData();
      data.append("user_uuid", localStorage.getItem("uuid"));

      axios(dashboardConfig(data))
        .then((response) => {
          this.profileData = response.data.profiles;
        })
        .catch((error) => {
          localStorage.removeItem("uuid");
          location.reload();
          console.log(error);
        });
    }
  },
  methods: {
    changeTab(index) {
      const images = document.getElementsByClassName(
        "account-page-content-image-file"
      );

      Array.from(images).forEach((image) => {
        image.style.opacity = "0";
      });

      setTimeout(() => {
        this.tabs.forEach((tab) => {
          tab.status = false;
        });

        this.tabs[index].status = true;

        Array.from(images).forEach((image) => {
          image.style.opacity = "1";
        });
      }, 250);
    },
    showSlideMenu() {
      console.log(router)
      this.isSlideMenuVisible = !this.isSlideMenuVisible;
      if (this.isSlideMenuVisible) {
        document.querySelector(".slider").style.display = "flex";
      } else {
        document.querySelector(".slider").style.display = "none";
      }
    },
  },
  computed: {
    filteredImage() {
      return this.tabs.filter((blocks) => blocks.status == true);
    },
    slideMenuStyle() {
      if (this.isSlideMenuVisible) {
        return `
          transform: scale(0.8) !important;
          right: 60% !important;
          overflow: auto;
          pointer-events: none;
          transition: all ease-in-out 250ms !important;
          top: 20% !important;
          height: 80vh !important;
          padding-bottom: 0 !important;
          box-shadow: 0 0 30px 0 !important;
          position: relative;
          border-radius: 30px;
        `;
      } else {
        return `
          right: 0% !important;
          transition: all ease-in-out 250ms !important;
          position: relative;
        `;
      }
    },
  },
};
</script>
