import baseUrl from "./baseUrl";

function linkConfig(data) {
  return {
    method: "post",
    url: `${baseUrl}/api/v1/dashboard/getAllReservedLinks`,
    data: data,
  };
}

export default linkConfig;
