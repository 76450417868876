<template>
  <div class="select-profile" component="selectProfileModal">
    <div class="select-profile-modal">
      <div class="select-profile-modal-header">
        <v-icon @click="$emit('close')">mdi-close-circle-outline</v-icon>
        <div class="select-profile-modal-header-title">انتخاب پروفایل</div>
      </div>
      <div class="select-profile-modal-content">
        <div
        class="select-profile-modal-content-card"
        v-for="(profile, index) in profilesData"
        :key="index"
        @click="changeProfile(profile)"
        >
          <div class="select-profile-modal-content-card-header">
            <div class="select-profile-modal-content-card-header-radio">
              <div
                class="select-profile-modal-content-card-header-radio-parrent"
              >
                <div
                  class="select-profile-modal-content-card-header-radio-parrent-chiled"
                  :style="profile.id==this.selectProfile.id?'background: #009606;':null"
                ></div>
              </div>
            </div>
            <div class="select-profile-modal-content-card-header-address">
              {{ location+"/"+profile.link }}
            </div>
          </div>
          <div class="select-profile-modal-content-card-content">
            <v-img
              :src="profile.img"
              lazy-src="./assets/images/content/defaultProfile.png"
              class="select-profile-modal-content-card-content-image"
            />
            <div class="select-profile-modal-content-card-content-description">
              <div
                class="select-profile-modal-content-card-content-description-title"
              >
                {{ profile.title }}
              </div>
              <div
                class="select-profile-modal-content-card-content-description-subTitle"
              >
                {{ profile.subtitle }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.select-profile {
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(128, 128, 128, 0.5215686275);
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: end;
  z-index: 2;
  &-modal {
    max-width: 600px;
    width: 100%;
    background: white;
    height: 90vh;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    overflow-y: scroll;
    -ms-overflow-style: none;  
  scrollbar-width: none;
  ::-webkit-scrollbar {
    width: 0 !important;
    height: 0 !important;
  }
    &-header {
      display: flex;
  flex-direction: row-reverse;
  padding: 16px;
  box-shadow: 0px 1px 5px 0px #939393;
  position: sticky;
  top: 0;
  z-index: 10;
  background: white;
      i {
      }
      &-title{
        flex: 1;
        text-align: center;
        padding-left: 24px;
      }
    }
    &-content {
      display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 16px 0px;
      &-card {
        display: flex;
        flex-direction: column;
        gap: 16px;
        border-radius: 16px;
        margin: 0px 24px;
        padding: 20px;
        box-shadow: 0px 1px 5px 0px #939393;
        &-header {
            display: flex;
  gap: 8px;
  align-items: center;
          &-radio {
            &-parrent {
              width: 24px;
              height: 24px;
              border-radius: 50%;
              border: 3px solid gray;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              padding: 2px;
              &-chiled {
                border-radius: 50%;
                width: 100%;
                height: 100%;
              }
            }
          }
          &-address {
            width: 100%;
  background: #009606;
  color: white;
  padding: 8px;
  border-radius: 8px;
          }
        }
        &-content {
          display: flex;
          flex-direction: row-reverse;
          gap: 8px;
          &-image {
            width: 80px !important;
            height: 80px !important;
            border-radius: 50% !important;
          }
          &-description {
            display: flex;
            flex-direction: column;
            align-items: end;
            width: 100%;
            &-title {
              text-align: end;
            }
            &-subTitle {
              text-align: end;
            }
          }
        }
      }
    }
  }
}
</style>
<script>
export default {
  name: "selectProfileModal",
  props: {
    profiles: Object,
    selectedProfile:Object,
  },
  data: () => ({
    profilesData: [],
    location:location.host,
    selectProfile:[]
  }),
  methods:{
    changeProfile(profile){
      this.selectProfile=profile
      this.$emit('changeProfile',profile)
    }
  },
  mounted() {
    this.profilesData = this.profiles;
    this.selectProfile=this.selectedProfile
  },
};
</script>
