<template>
  <ezyLoading v-if="loading && !notFound" />
  <NotFound v-else-if="!pageData && notFound" />
  <div
    v-else-if="pageData && !notFound"
    class="menu-page"
    :style="[backgroundImg, fontStyle]"
  >
    <div class="menu-page-profile" style="overflow-y: auto">
      <visit-component
        v-if="
          pageData.profile.profile_option.headerVisible == 'true' ||
          pageData.profile.profile_option.headerVisible == 1
        "
        :profileData="pageData.profile"
      />
    </div>
    <div class="menu-page-content" style="overflow-y: auto">
      <div
        class="menu"
        v-for="blocks in filteredMenuData"
        :key="blocks.sort"
        v-show="
          blocks.block_option.blockVisibility == 'true' ||
          blocks.block_option.blockVisibility == 1
        "
      >
        <ezyVisitBlocks
          v-if="blocks.pb_option.length > 0"
          :profileOption="pageData.profile.profile_option"
          :blockTitle="blocks.block_option.blockTitle"
          :elements="blocks.pb_option"
          :options="blocks.block_option"
        />
        <menuComponent
          v-if="blocks.menu.length > 0"
          :blockTitle="blocks.block_option.blockTitle"
          :menuData="blocks.menu"
          :menuOption="blocks"
          :id="blocks.id"
          :openMenuId="openMenuId"
          @update:openMenuId="openMenuId = $event"
        />
        <routingMap
          v-if="blocks.map.length > 0"
          :option="blocks.block_option"
          :mapData="blocks.map"
        />
        <textBlock
          v-if="blocks.text.length > 0"
          :blockTitle="blocks.block_option.blockTitle"
          :textData="blocks.text"
        />
        <videoBlock
          v-if="blocks.video.length > 0"
          :blockTitle="blocks.block_option.blockTitle"
          :videos="blocks.video"
        />
        <customerClub
          v-if="blocks.club.length > 0"
          :option="blocks.block_option"
          :profile_id="blocks.profile_id"
          :club="blocks.club"
        />
        <bannerBlock
          v-if="blocks.banner.length > 0"
          :blockTitle="blocks.block_option.blockTitle"
          :banners="blocks.banner"
        />
      </div>
    </div>
  </div>
  <div v-else>Loading...</div>
</template>

<script>
import axios from "axios";
import previewConfig from "@/services/preview";
import visitComponent from "@/components/visit/index.vue";
import menuComponent from "@/components/menu/index.vue";
import ezyVisitBlocks from "@/components/visitBlocks/index.vue";
import textBlock from "@/components/textBlock/index.vue";
import bannerBlock from "@/components/banner/index.vue";
import videoBlock from "@/components/video/index.vue";
import ezyLoading from "@/components/loading/index.vue";
import NotFound from "@/components/404/index.vue";
import customerClub from "@/components/customerClub/index.vue";
import routingMap from "@/components/routingBlock/index.vue";
export default {
  name: "menuPage",
  data: () => ({
    path: window.location.pathname.split("/")[1],
    pageData: "",
    menuData: "",
    openMenuId: null,
    loading: true,
    notFound: false,
  }),
  components: {
    customerClub,
    visitComponent,
    menuComponent,
    ezyVisitBlocks,
    textBlock,
    bannerBlock,
    videoBlock,
    ezyLoading,
    NotFound,
    routingMap,
  },
  computed: {
    filteredMenuData() {
      return this.menuData.filter(
        (blocks) =>
          blocks.pb_option.length > 0 ||
          blocks.menu.length > 0 ||
          blocks.text.length > 0 ||
          blocks.video.length > 0 ||
          blocks.banner.length > 0 ||
          blocks.club.length > 0 ||
          blocks.map.length > 0
      );
    },
    fontStyle() {
      if (this.pageData.profile.profile_option.font_name) {
        return `
      font-family: "${this.pageData.profile.profile_option.font_name}", sans-serif;
      `;
      } else {
        return ``;
      }
    },
    backgroundImg() {
      if (this.pageData.profile.profile_option.background_image_url) {
        return `
    background-image: url(${this.pageData.profile.profile_option.background_image_url});
            background-color: white;
  background-position: center center !important;
  background-repeat: no-repeat !important;
  background-attachment: fixed !important;
  max-width:600px;
        `;
      } else if (this.pageData.profile.profile_option.background_color) {
        return `
        background:${this.pageData.profile.profile_option.background_color};
        `;
      } else {
        return ``;
      }
    },
  },
  methods: {},
  mounted() {
    const formData = new FormData();
    formData.append("link", this.path);
    axios(previewConfig(formData))
      .then((response) => {
        if (response.data == "Profile not found") {
          this.notFound = true;
        } else if (response.data.redirect_to) {
          window.location.href = response.data.redirect_to;
        } else {
          this.pageData = response.data;
          this.menuData = response.data.blocks;
          this.loading = false;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },
};
</script>

<style lang="scss">
.menu-page {
  min-height: 100vh;
  height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;
  background-size: 600px 100%;
  -ms-overflow-style: none;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    width: 0 !important;
    height: 0 !important;
  }
}

@media (max-width: 600px) {
  .menu-page {
    min-height: unset;
    height: 100%;
    content: "";
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    //z-index:-1;
    -webkit-background-size: 100% 100vh;
    -moz-background-size: 100% 100vh;
    -o-background-size: 100% 100vh;
    background-size: 100% 100vh;
  }
}
.menu {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 10px;
}
</style>
<style>
.edit-page:deep(*) {
  font-family: inherit !important;
}
</style>
