<template>
  <div class="video-block" component="videoBlock">
    <div class="video-container">
      <div class="video" v-html="videoTag"></div>
      <div v-if="disableInternalEvents" class="overlay" @click="eventHandler"></div>
    </div>
  </div>
</template>

<style>
.video-block {
  width: 100%;
  position: relative;
  padding-bottom: 56.25%; /* 16:9 ratio */
}

.video-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  cursor: pointer;
}
</style>
<script>
export default {
  name: "videoBlock",
  data: () => ({
    videoData: "",
    videoTag: "",
  }),
  props: {
    videos: Object,
    blockTitle: String,
    disableInternalEvents: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    if (this.videos) {
      this.initializeMenuData(this.videos);
    }
  },
  watch: {
    videos: {
      handler(newVideos) {
        this.initializeMenuData(newVideos);
      },
      deep: true,
    },
  },
  methods: {
    initializeMenuData(videos) {
      this.videoData = videos[0];
      if (this.videoData) {
        this.createVideo();
      }
    },
    eventHandler(event) {
      event.stopPropagation();
      event.preventDefault(); 
      this.$emit('click', event); 
    },
    createVideo() {
      const videoLink = this.videoData.link;
      let iframe = '';

      if (videoLink.includes('aparat.com')) {
        const iframeLink = videoLink.split('/');
        iframe = `<iframe src="https://www.aparat.com/video/video/embed/videohash/${iframeLink[iframeLink.length - 1]}/vt/frame" width="640" allowfullscreen="true" webkitallowfullscreen="true" mozallowfullscreen="true"></iframe>`;
      } else if (videoLink.includes('youtube.com') || videoLink.includes('youtu.be')) {
        let videoId = '';
        if (videoLink.includes('youtu.be')) {
          videoId = videoLink.split('/').pop();
        } else if (videoLink.includes('youtube.com')) {
          const urlParams = new URLSearchParams(new URL(videoLink).search);
          videoId = urlParams.get('v');
        }
        iframe = `<iframe width="560" src="https://www.youtube.com/embed/${videoId}" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`;
      }

      this.videoTag = iframe;
    },
  },
};
</script>
