import { createRouter, createWebHistory } from 'vue-router';
import loginPage from '@/pages/login/index.vue';
import pageCreator from '@/pages/pageCreator/index.vue';
// import editPage from "@/pages/edit/index.vue"
import profilePage from "@/pages/profile/index.vue"
import menuPage from '@/pages/menu/index.vue'; 
import campaignFrom from "@/components/campaignFrom/index.vue"

const routes = [
  { path: '/', component: loginPage },
  { path: '/pageCreator', component: pageCreator },
  { path: '/edit/:pathMatch(.*)*', component: () => import("@/pages/edit/index.vue") },
  { path: '/:pathMatch(.*)*', component: menuPage },
  { path:'/profile', component:profilePage},
  { path:'/form', component:campaignFrom}
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
