import baseUrl from "./baseUrl";

function submitPage(data) {
  return {
    method: "post",
    url: `${baseUrl}/api/v1/dashboard/submitNewProfile`,
    headers: {
      Accept: "application/json",
    },
    data: data,
  };
}

export default submitPage;
