<template>
  <div
    v-if="profileData"
    class="menuComponent"
    component="menuComponent"
  >
    <div class="profile">
      <ezyProfile
        :bgImg="profileData.bg_img"
        :profileImg="profileData.img"
        :profileName="profileData.title"
        :profileTitle="profileData.subtitle"
        :textColor="profileData.textColor"
        :bgBorder="profileData.bg_border"
        :profileBorder="profileData.img_border"
      />
    </div>
  </div>
  <div v-else>Loading...</div>
</template>
<script>
import ezyProfile from "@/components/profile/index.vue";
export default {
  name: "visitComponent",
  components: {
    ezyProfile,
  },
  props: {
    profileData: {
      type: Object,
      default: () => ({
        bg_img: "",
        img: "",
        title: "",
        subtitle: "",
        textColor: "",
        bg_border: "",
        img_border: "",
      }),
    },
  },
};
</script>
