<template>
  <div class="login-form" component="loginForm">
    <div class="login-form-title">
      <div class="login-form-title-text">
        به
        <div class="login-form-title-text-lighten text-green">ایزی کانکت</div>
        خوش اومدی
      </div>
      <div class="login-form-title-sub">
        برای ورود به اپلیکیشن، شماره تماس خود را وارد کن.
      </div>
    </div>
    <div class="login-form-inputs">
      <div class="login-form-input-username">
        <v-text-field
          class="text-end username"
          :rounded="true"
          color="#2e7d32"
          base-color="#2e7d32"
          bg-color="green-lighten-5"
          density="compact"
          variant="outlined"
          v-model="username"
          :rules="[numberRule]"
          @focus="usernamefocus"
          @blur="usernameblur"
          :placeholder="usernamePlaceholder"
          type="number"
          hide-spin-buttons
        ></v-text-field>
      </div>
      <div class="login-form-input-password">
        <v-text-field
          density="compact"
          base-color="#2e7d32"
          :rounded="true"
          bg-color="green-lighten-5"
          center-affix
          color="#2e7d32"
          variant="outlined"
          class="text-end password"
          type="password"
          height="56"
          v-model="password"
          :rules="[passwordRule]"
          @focus="passwordfocus"
          @blur="passwordblur"
          @keyup.enter="submit"
          :placeholder="this.passwordPlaceholder"
        ></v-text-field>
      </div>
    </div>
    <div class="login-form-buttons">
      
      <v-btn
        @click="submit()"
        width="50%"
        color="#009606"
        class="login-form-buttons-submit"
        >ورود</v-btn
      >
      <a href="https://t.me/+989354886180" target="_blank" class="login-form-buttons-forgetPassword">
        <div class="login-form-buttons-forgetPassword-text">فراموشی رمز پشتیبانی تلگرام</div>
        <img src="@/assets/images/content/support.png" alt="">
      </a>
    </div>
  </div>
</template>
<style lang="scss">
@import "./styles/index.scss";
</style>
<script>
import axios from "axios";
import loginConfig from "@/services/login";
import router from "@/router/index.js";
export default {
  name: "loginForm",
  data: () => ({
    isUsernameValid: false,
    isPasswordValid: false,
    usernamePlaceholder: "نام کاربری",
    username: "",
    numberRule: (v) => {
      if (v.trim() === "") {
        return "لطفا شماره تلفن خود را وارد کنید";
      }
      if (
        !isNaN(parseFloat(v)) &&
        v.startsWith("09") &&
        v.length >= 11 &&
        v.length <= 11
      ) {
        return true;
      }
      return "لطفا شماره تلفن صحیح وارد کنید.";
    },
    passwordPlaceholder: "رمز عبور",
    password: "",
    passwordRule: (v) => {
      if (v.trim()) {
        return true;
      }
      return "لطفا رمز عبور خود را وارد کنید.";
    },
  }),

  mounted(){
const uuid = localStorage.getItem("uuid");
  if (uuid) {
    router.replace("/pageCreator");
  }
  },
  methods: {
    usernamefocus() {
      this.usernamePlaceholder = "";
    },
    usernameblur() {
      if (this.username.trim() == "") {
        this.usernamePlaceholder = "نام کاربری";
      }
    },
    passwordfocus() {
      this.passwordPlaceholder = "";
    },
    passwordblur() {
      if (this.password.trim() == "") {
        this.passwordPlaceholder = "رمز عبور";
      }
    },
    submit() {
      if (
        !isNaN(parseFloat(this.username)) &&
        this.username.startsWith("09") &&
        this.username.length >= 11 &&
        this.username.length <= 11
      ) {
        this.isUsernameValid = true;
      } else {
        this.isUsernameValid = false;
      }
      if (this.password.trim()) {
        this.isPasswordValid = true;
      } else {
        this.isPasswordValid = false;
      }
      const formData = new FormData();
      formData.append("phone", this.username);
      formData.append("password", this.password);

      this.numberRule(this.username);
      this.passwordRule(this.password);

      if (this.isUsernameValid && this.isPasswordValid) {
        axios(loginConfig(formData))
          .then((response) => {
            if (response.data.status === 1 || response.data.status === 2) {              this.$swal({
                title: "نام کاربری یا رمز عبور اشتباه است",
                icon: "warning",
              });
            }else{
              localStorage.setItem("uuid", response.data.uuid);
              location.reload()
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        if (!this.isUsernameValid || !this.isPasswordValid) {
          this.$swal({
            title: "لطفا فیلد های الزامی را پر کنید",
            icon: "warning",
          });
        }
      }
    },
  },
};
</script>
