<template>
      <ezyLoading v-if="loading" />

  <div class="empty" v-if="empty&&!loading">
    <empty-Result-Page />
  </div>
  <div v-else-if="!empty&&!loading" class="page-creator-content" component="pageCreatorContent">
    <div class="cards">
      <page-Card v-for="card in pageData" :profileId="card.id" :key="card.id"  :title="card.title" :subTitle="card.subtitle" :link="card.link" :image="card.img" />

    </div>
    <div class="buttons">
      
    </div>
    </div>
</template>
<style lang="scss">
@import "./styles/index.scss";
</style>
<script>
import axios from 'axios';
import dashboardConfig from "@/services/dashboard.js";
import pageCard from "@/components/pageCard/index.vue";
import emptyResultPage from "@/components/emptyPageCreator/index.vue"
import ezyLoading from "@/components/loading/index.vue"

export default {
  name: "pageCreatorContent",
  data: () => ({
    pageData:[],
    empty:true,
    hostname:window.location.hostname,
    address:window.location.origin,
    loading:true,
  }),
  components:{
    pageCard,
    emptyResultPage,
    ezyLoading
  },
  mounted() {
  const data = new FormData();
  data.append("user_uuid", localStorage.getItem("uuid"));

  axios(dashboardConfig(data))
    .then((response) => {
        this.loading=false
        this.pageData = response.data.profiles;
        if(this.pageData.length > 0){
          this.empty=false
        }
    })
    .catch((error) => {
      localStorage.removeItem("uuid")
      location.reload()
      console.log(error);
    });
},
};
</script>
