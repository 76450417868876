<template>
<div class="information-block" component="profile-information-block">
    <div class="information-block-slider">
            <!-- image swiper -->
             <img class="information-block-slider-image" src="../../assets/images/content/refralPicture.png" alt="">
    </div>
    <div class="information-block-content">
        <div v-for="item in blockItems" :key="item.icon" @click="$emit(`${item.method}`)" class="information-block-content-items">
            <div class="information-block-content-items-image"
            :style="`background-image:url(${item.image})`">
                
            </div>
            <div class="information-block-content-items-info">
                <div class="information-block-content-items-info-title">{{ item.title }}</div>
                <div class="information-block-content-items-info-sub">{{ item.sub }}</div>
            </div>
            <div class="information-block-content-items-arrow" style="background-image: url('../assets/images/icons/Vector.svg');">
            </div>
        </div>
    </div>
</div>
</template>
<style lang="scss" scoped>
@import "./styles/index.scss";
</style>
<script>
export default {
    name:"profileInformationBlock",
    data:()=>({
        blockItems:[
        {
                icon:"account",
                image:"../assets/images/icons/profile.svg",
                title:"اطلاعات حساب",
                sub:"جزئیات حساب",
                method:"accountInformation"
            },
            {
                icon:"password",
                image:"../assets/images/icons/password.svg",
                title:"امنیت",
                sub:"تغییر رمز و حریم خصوصی",
                method:"privacy"
            },
            {
                icon:"refral",
                image:"../assets/images/icons/refral.svg",
                title:"معرفی دوستان",
                sub:"جوایز و امتیاز",
                method:"refral"
            },
        ]
    }),
}
</script>