<template>
  <div :class="`visit-block  ${width}`" component="visitBlock">
    <a :href="(link ? link : '') + connectionWay">
      <button :style="buttonStyle" class="visit-block-element">
        <div class="visit-block-element-text">
          <div :style="textStyle" class="visit-block-element-text-title">
            {{ title }}
          </div>
          <div
            style="display: flex"
            v-if=" blockItemColor == 2"
          >
            <span v-if="icon.length > 0" style="position: unset" :class="icon">
              <span
                v-for="index in 50"
                :key="index"
                :class="`path${index}`"
              ></span>
            </span>
          </div>
          <div style="display: flex" v-if="blockItemColor == 3||blockItemColor == 1">
            <span
              :style="textStyle"
              v-if="icon.length > 0"
              style="position: unset"
              :class="`${icon}-solid`"
            >
            </span>
          </div>
        </div>
      </button>
    </a>
  </div>
</template>

<style lang="scss">
@import "./styles/index.scss";
@import "@/assets/ezy-icon/ezyFont.css";
.ez {
  font-size: 20px;
}
</style>

<script>
export default {
  name: "visitBlock",
  data: () => ({
    backgroundImage: "",
    textColor: "",
    borderColor: "",
    borderRadius: "",
  }),
  mounted() {
    this.textColor = this.textBlockItemColor;
    this.borderColor = this.borderBlockItemColor;

    if(this.blockItemColor == "1"){
      this.backgroundImage = this.profileOption.block_background_color;
      this.textColor = this.profileOption.block_text_color;
      this.borderColor = this.profileOption.block_border_color;
    }else
    if (this.blockItemColor == "3") {
      this.backgroundImage = this.bgBlockItemColor;
    } else if (this.blockItemColor == "2") {
      this.backgroundImage = this.defaultColor;
    }

    if (this.blockBorder == "3") {
      this.borderRadius = "100px";
    } else if (this.blockBorder == "2") {
      this.borderRadius = "10px";
    } else if (this.blockBorder == "1") {
      this.borderRadius = "4px";
    } else if (this.blockBorder == "0") {
      this.borderRadius = "0";
    }
    if (this.blockBorder == "rounded") {
      this.borderRadius = "4px";
    }

    if (this.textColor == null) {
      this.textColor = "black";
    }
  },
  // updated(){
  //   this.textColor = this.textBlockItemColor;
  //   this.borderColor = this.borderBlockItemColor;

  //   if (this.blockItemColor == "3" || this.blockItemColor == "1") {
  //     this.backgroundImage = this.bgBlockItemColor;
  //   } else {
  //     this.backgroundImage = this.defaultColor;
  //   }

  //   if (this.blockBorder == "3") {
  //     this.borderRadius = "100px";
  //   } else if (this.blockBorder == "2") {
  //     this.borderRadius = "10px";
  //   } else if (this.blockBorder == "1") {
  //     this.borderRadius = "4px";
  //   } else if (this.blockBorder == "0") {
  //     this.borderRadius = "0";
  //   } if (this.blockBorder == "rounded") {
  //     this.borderRadius = "4px";
  //   }

  //   if (this.textColor == null) {
  //     this.textColor = "black";
  //   }
  // },
  computed: {
    buttonStyle() {
      return `background: ${this.backgroundImage} !important;
      background-color:${this.backgroundImage};
            min-height: 40px;
            height: auto;
            border-radius: ${this.borderRadius} !important;
             border: 1px solid ${this.borderColor};`;
    },
    textStyle() {
      return `background:${this.textColor};
    background-clip: border-box;
  background-clip: border-box;
  -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
  `;
    },
  },
  props: {
    title: String,
    link: String,
    blockItemColor: String,
    bgBlockItemColor: String,
    textBlockItemColor: String,
    borderBlockItemColor: String,
    defaultColor: String,
    blockBorder: String,
    connectionWay: String,
    icon: String,
    width: String,
    profileOption: Object,

  },
};
</script>
