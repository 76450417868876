<template>
  <div @click.self="$emit('closeQr')" class="qr-card" component="qrCard">
    <div class="qr-card-modal">
      <div class="qr-card-modal-content">
        <qr-code ref="qrcode" :size="200" :text="text"></qr-code>
      </div>
      <div class="qr-card-modal-buttons">
        <v-btn
          @click="downloadImg"
          icon="mdi-download"
          class="qr-card-modal-buttons-download"
        ></v-btn>

        <v-btn
          :title="text"
          v-if="isSupported"
          v-show="showShareBtn"
          @click="startShare"
          class="qr-card-modal-buttons-share"
          icon="mdi-share"
        ></v-btn>
      </div>
    </div>
  </div>
</template>

<style scoped>
@import "./styles/index.scss";
</style>

<script>
import { ref, onMounted, nextTick } from "vue";
import { useShare } from "@vueuse/core";
import { saveAs } from "file-saver";

export default {
  name: "qrCard",
  props: {
    text: String,
  },
  setup(props) {
    const qrCodeRef = ref(null);
    const options = ref({
      title: "VueUse",
      text: "منو دیجیتال کافمون",
      url: props.text,
    });

    const { share, isSupported } = useShare(options);
    const showShareBtn = ref(false);

    const detectOS = () => {
      if (
        navigator.userAgent.includes("Android") ||
        navigator.userAgent.includes("like Mac")
      ) {
        showShareBtn.value = true;
      }
    };

    const startShare = async () => {
      try {
        await share();
      } catch (err) {
        console.error("Error sharing:", err);
      }
    };

    const downloadImg = async () => {
      await nextTick(); // Wait for DOM updates
      const qr = document.querySelector(`[title="${props.text}"]`);
      const qrElement = qr.querySelector("canvas");
      if (qrElement) {
        qrElement.toBlob((blob) => {
          saveAs(blob, "qrcode.png");
        });
      } else {
        console.error("QR code canvas element not found.");
      }
    };

    onMounted(() => {
      detectOS();
    });

    return {
      isSupported,
      showShareBtn,
      startShare,
      downloadImg,
      qrCodeRef,
    };
  },
};
</script>
