<template>
    <div class="swiper swiper-scale-effect">
      <!-- Swiper -->
      <div class="swiper-container">
        <!-- Additional required wrapper -->
        <div class="swiper-wrapper">
          <!-- Slides -->
          <div class="swiper-slide">
            <div class="slide-01 swiper-slide-cover"></div>
            <div>Slide 01</div>
          </div>
          <div class="swiper-slide">
            <div class="slide-02 swiper-slide-cover"></div>
            <div>Slide 02</div>
          </div>
          <div class="swiper-slide">
            <div class="slide-03 swiper-slide-cover"></div>
            <div>Slide 03</div>
          </div>
          <!-- Add more slides as needed -->
        </div>
  
        <!-- Add pagination -->
        <div class="swiper-pagination"></div>
        <!-- Add navigation arrows -->
        <div class="swiper-button-prev"></div>
        <div class="swiper-button-next"></div>
      </div>
    </div>
  </template>
  
  <script>
  import { Swiper, Navigation, Pagination, Autoplay, EffectFade } from 'swiper';
  import 'swiper/css';
  import 'swiper/css/navigation';
  import 'swiper/css/pagination';
  import 'swiper/css/effect-fade';
  
  export default {
    name: 'MySwiperComponent',
    mounted() {
      this.initSwiper();
    },
    methods: {
      initSwiper() {
        new Swiper('.swiper-container', {
          modules: [Navigation, Pagination, Autoplay, EffectFade],
          direction: 'horizontal', // یا 'vertical'
          loop: true,
          centeredSlides: true,
  
          // If we need pagination
          pagination: {
            el: '.swiper-pagination',
            clickable: true,
            dynamicBullets: true,
          },
  
          // Navigation arrows
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          },
  
          // Autoplay
          autoplay: {
            delay: 5000,
            disableOnInteraction: false,
          },
  
          // Use slide or fade transition effect
          speed: 2000,
          effect: 'fade',
          fadeEffect: {
            crossFade: true,
          },
        });
      },
    },
  };
  </script>
<style lang="scss" scoped>
@import "./styles/index.scss";
</style>
