<template>
  <div class="slider">
    <div class="slider-image">
      <img
        class="slider-image-file"
        src="@/assets/images/content/slide-menu-img.png"
        alt=""
      />
    </div>
    <div class="slider-items">
      <router-link
        @click="openProfile"
        to="/pageCreator"
        class="slider-items-dashboard"
      >
        داشبرد ایزی کانکت
        <i class="slider-items-dashboard-icon"></i>
      </router-link>
      <router-link
        @click="openProfile"
        to="/profile"
        class="slider-items-account"
      >
        حساب کاربری
        <v-icon>mdi-account-circle-outline</v-icon>
      </router-link>
      <div class="slider-items-question">
        سوالات متداول
        <v-icon>mdi-help-circle-outline</v-icon>
      </div>
      <div class="slider-items-education">
        آموزش
        <i class="slider-items-education-icon"></i>
      </div>
      <a
        href="https://t.me/+989354886180"
        target="_blank"
        class="slider-items-support"
      >
        پشتیبانی
        <i class="slider-items-support-icon"></i>
      </a>
      <v-divider :thickness="1" class="border-opacity-100"></v-divider>
      <div @click="logOut" class="slider-items-logout">
        خروج
        <v-icon>mdi-logout</v-icon>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.slider {
  font-family: "morabba-regular", "poppins-regular";

  width: 100%;
  max-width: 600px;
  min-height: 100vh;
  background: rgb(0, 150, 6);
  background: linear-gradient(
    48deg,
    rgba(0, 150, 6, 1) 0%,
    rgba(98, 223, 133, 1) 100%
  );
  position: absolute;
  display: none;
  flex-direction: column;
  align-items: end;
  justify-content: space-around;
  &-image {
    padding-right: 10px;
  }
  &-items {
    width: 50%;
    direction: ltr;
    text-align: end;
    display: flex;
    flex-direction: column;
    gap: 8px;
    &-dashboard {
      display: flex;
      align-items: center;
      justify-content: end;
      gap: 3px;
      &-icon {
        width: 26px;
        height: 26px;
        display: inline-block;
        background-image: url('data:image/svg+xml,<svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M10.3144 10.9219H7.36501C6.93223 10.9184 6.51575 11.0869 6.20721 11.3903C5.89865 11.6938 5.72329 12.1074 5.71973 12.5402V18.6647C5.72773 19.5656 6.4642 20.2895 7.36501 20.2819H10.3144C10.7472 20.2856 11.1637 20.1171 11.4723 19.8136C11.7808 19.5101 11.9562 19.0965 11.9597 18.6637V12.5402C11.9562 12.1074 11.7808 11.6938 11.4723 11.3903C11.1637 11.0869 10.7472 10.9184 10.3144 10.9219Z" stroke="white" stroke-width="1.56" stroke-linecap="round" stroke-linejoin="round"/><path fill-rule="evenodd" clip-rule="evenodd" d="M10.3144 4.67836H7.36501C6.48232 4.65388 5.74634 5.34846 5.71973 6.23108V7.28564C5.74634 8.16828 6.48232 8.86286 7.36501 8.83836H10.3144C11.1971 8.86286 11.9331 8.16828 11.9597 7.28564V6.23108C11.9331 5.34846 11.1971 4.65388 10.3144 4.67836Z" stroke="white" stroke-width="1.56" stroke-linecap="round" stroke-linejoin="round"/><path fill-rule="evenodd" clip-rule="evenodd" d="M15.6863 14.0378H18.6347C19.0676 14.0417 19.4844 13.8733 19.7932 13.5698C20.1019 13.2663 20.2775 12.8525 20.281 12.4196V6.29603C20.2775 5.86327 20.1021 5.44964 19.7936 5.14616C19.485 4.84267 19.0685 4.67419 18.6357 4.67779H15.6863C15.2536 4.67419 14.837 4.84267 14.5285 5.14616C14.2199 5.44964 14.0446 5.86327 14.041 6.29603V12.4196C14.0446 12.8523 14.2199 13.2659 14.5285 13.5694C14.837 13.8729 15.2536 14.0414 15.6863 14.0378Z" stroke="white" stroke-width="1.56" stroke-linecap="round" stroke-linejoin="round"/><path fill-rule="evenodd" clip-rule="evenodd" d="M15.6863 20.2778H18.6347C19.5178 20.3029 20.2544 19.6082 20.281 18.7251V17.6705C20.2544 16.7879 19.5184 16.0934 18.6357 16.1178H15.6863C14.8036 16.0934 14.0676 16.7879 14.041 17.6705V18.7241C14.067 19.6071 14.8032 20.3023 15.6863 20.2778Z" stroke="white" stroke-width="1.56" stroke-linecap="round" stroke-linejoin="round"/></svg>');
      }
    }
    a {
      text-decoration: inherit;
      padding-right: 10px;
      color: white;
      cursor: pointer;
      font-weight: bold;
      font-size: 18px;
      display: flex;
      align-items: center;
      justify-content: end;
      gap: 3px;
      .slider-items-support-icon {
        width: 26px;
        height: 26px;
        display: inline-block;
        background-image: url('data:image/svg+xml,<svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12.5 1.5625C7.34375 1.5625 3.125 5.70312 3.125 10.8594V13.5938C3.125 13.6719 3.125 13.6719 3.125 13.75C3.125 13.8281 3.125 13.9844 3.125 14.0625C3.125 16.25 4.84375 17.9688 7.03125 17.9688C7.5 17.9688 7.8125 17.6562 7.8125 17.1875V10.9375C7.8125 10.4688 7.5 10.1562 7.03125 10.1562C6.17188 10.1562 5.3125 10.4688 4.6875 10.9375V10.7812C4.6875 6.5625 8.20312 3.125 12.5 3.125C16.7969 3.125 20.3125 6.5625 20.3125 10.8594V10.9375C19.6875 10.4688 18.8281 10.1562 17.9688 10.1562C17.5 10.1562 17.1875 10.4688 17.1875 10.9375V17.1875C17.1875 17.6562 17.5 17.9688 17.9688 17.9688C18.5156 17.9688 19.0625 17.8125 19.5312 17.6562C18.75 19.2969 17.3438 20.5469 15.625 21.25C15.625 21.1719 15.625 21.1719 15.625 21.0938C15.625 20.625 15.3125 20.3125 14.8438 20.3125H12.5C12.0312 20.3125 11.7188 20.625 11.7188 21.0938V22.6562C11.7188 23.125 12.0312 23.4375 12.5 23.4375C17.6562 23.4375 21.875 19.375 21.875 14.375V13.5938V11.7188V10.8594C21.875 5.70312 17.6562 1.5625 12.5 1.5625Z" fill="white"/></svg>');
      }
    }
    div {
      padding-right: 10px;
      color: white;
      cursor: pointer;
      font-weight: bold;
      font-size: 18px;
      display: flex;
      align-items: center;
      justify-content: end;
      gap: 3px;
      .slider-items-education-icon {
        width: 26px;
        height: 26px;
        display: inline-block;
        background-image: url('data:image/svg+xml,<svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M17.1084 10.6931L19.8637 9.03989C20.7117 8.53104 21.1357 8.27662 21.4846 8.30915C21.7889 8.33751 22.0664 8.49466 22.2472 8.74094C22.4547 9.02338 22.4547 9.51788 22.4547 10.5069V15.1563C22.4547 16.1452 22.4547 16.6397 22.2472 16.9222C22.0664 17.1685 21.7889 17.3257 21.4846 17.354C21.1357 17.3865 20.7117 17.1321 19.8637 16.6233L17.1084 14.9701M6.62964 19.2471H13.6868C14.8844 19.2471 15.4833 19.2471 15.9408 19.014C16.3431 18.8091 16.6703 18.4819 16.8753 18.0795C17.1084 17.6221 17.1084 17.0232 17.1084 15.8255V9.83765C17.1084 8.63997 17.1084 8.04112 16.8753 7.58367C16.6703 7.18127 16.3431 6.85412 15.9408 6.6491C15.4833 6.41602 14.8844 6.41602 13.6868 6.41602H6.62964C5.43196 6.41602 4.83311 6.41602 4.37566 6.6491C3.97327 6.85412 3.64612 7.18127 3.4411 7.58367C3.20801 8.04112 3.20801 8.63996 3.20801 9.83765V15.8255C3.20801 17.0232 3.20801 17.6221 3.4411 18.0795C3.64612 18.4819 3.97327 18.8091 4.37566 19.014C4.83311 19.2471 5.43195 19.2471 6.62964 19.2471Z" stroke="white" stroke-width="2.13852" stroke-linecap="round" stroke-linejoin="round"/></svg>');
      }
    }
    hr {
      color: white;
      border-radius: 100% 0%;
    }
  }
}
</style>
<script>
/* eslint-disable */
export default {
  name: "menuSlide",
  methods: {
    openProfile() {
      document.querySelector(".slider").style.display = "none";
    },
    logOut() {
      this.$swal({
        icon: "warning",
        title: "آیا برای خروج از حساب کاربری خود اطمینان دارید؟",
        showCancelButton: true,
        confirmButtonText: "خروج",
        cancelButtonText: "انصراف",
        confirmButtonColor: "#dc3545",
      }).then((result) => {
        if (result.isConfirmed) {
          localStorage.removeItem("uuid");
          location.reload();
        } else if (result.isDenied) {
        }
      });
    },
  },
};
</script>
