<template>
  <div class="subscription-block" component="subscriptionBlock">
    <div class="subscription-block-card">
      <div class="subscription-block-card-content">
        <div class="subscription-block-card-content-title">
            <div class="subscription-block-card-content-title-avatar">
                <img src="./diamond.svg" alt="" class="subscription-block-card-content-title-avatar-image">
            </div>
            <div class="subscription-block-card-content-title-content">
                <div class="subscription-block-card-content-title-content-title">اشتراک دائمی</div>
                <div class="subscription-block-card-content-title-content-sub">سطح تجاری</div>
            </div>
            <div class="subscription-block-card-content-title-time">
                <div class="subscription-block-card-content-title-time-title">دائم</div>
            </div>
        </div>
        <div class="subscription-block-card-content-upgradeAccount">
          <button class="subscription-block-card-content-upgradeAccount-button">
            ارتباط با پشتیبانی
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss">
@import "./styles/index.scss";
</style>
<script>
export default {
  name: "subscriptionBlock",
};
</script>
