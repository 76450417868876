<template> 
    <loginForm />
</template>   

<script>
import loginForm from "@/components/login"
export default {
    name:"loginPage",
    components:{
        loginForm
    }
}
</script>