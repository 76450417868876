import baseUrl from "./baseUrl";

function campaignSubmit(data) {
  return {
    method: 'post',
    url: `${baseUrl}/api/v1/cafeInfo/store`,
    headers: { 
       'Accept': 'application/json', 
    },
    data : data
 }

  
}

export default campaignSubmit;
