<template>
  <div class="banner-block" component="bannerBlock">
    <div v-if="bannerData.length > 1" class="carousel-banner">
      <v-carousel cycle hide-delimiters>
        <template v-slot:prev="{ props }">
          <v-icon variant="elevated" class="left" color="#0000002e" :height="500" @click="props.onClick" :size="50"
            >mdi-chevron-left
          </v-icon>
        </template>
        <template v-slot:next="{ props }">
          <v-icon variant="elevated" class="right" color="#0000002e" @click="props.onClick" :size="50"
            >mdi-chevron-right
          </v-icon>
        </template>
        <v-carousel-item
          v-for="banner in bannerData"
          :key="banner.id"
          :src="banner.img"
          @click="link(banner.link)"
        ></v-carousel-item>
      </v-carousel>
    </div>
    <div v-if="bannerData.length <= 1" class="single-banner">
        <div class="img" v-for="banner in bannerData"
        :key="banner.id">
      <v-img
        :src="banner.img"
        @click="link(banner.link)"
      >
      </v-img>
      <p>{{banner.description}}</p>
      </div>
    </div>
  </div>
</template>
<style>
.banner-block{
  min-height: 25px;
}
.right{
    height: 500px !important;
}
.left{
    height: 500px !important;
}
.v-window__controls{
    padding: 0;
}
.v-img__img{
  position: unset;
  border-radius: 8px !important;
}
</style>
<script>
/* eslint-disable */
export default {
  name: "bannerBlock",
  data: () => ({
    bannerData: "",
  }),
  props: {
    banners: Object,
    blockTitle:String,
    disableInternalEvents: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    link(url) {
      if (this.disableInternalEvents) {
        event.stopPropagation(); // جلوگیری از انتشار ایونت داخلی
        this.$emit('click', event); // انتشار ایونت به والد
        return;
      }
      if(url){
        window.open(url, "_blank");
      }
    },
  },
  mounted() {
    this.bannerData = this.banners;
  },
};
</script>
