<template>
  <div class="ezyProfile" component="ezyProfile">
    <div class="profile">
      <div class="profile-background">
        <img
        v-if="bgImg"
          :style="bgImgBorder"
          :src="bgImg"
          class="profile-background-img"
        />
      </div>
      <div class="profile-picture">
        <v-img
          lazy-src="../assets/images/content/defaultProfile.png"
          :style="profileImgBorder"
          :src="profileImg"
          class="profile-picture-img"
        />
      </div>
      <div class="profile-info">
        <div :style="`color:${textColor}`" v-if="profileName" class="profile-info-name">
          {{ profileName }}
        </div>
        <div :style="`color:${textColor}`" v-if="profileTitle" class="profile-info-title">
          {{ profileTitle }}
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss">
@import "./styles/index.scss";
</style>
<script>
export default {
  name: "ezyProfile",
  data: () => ({
    bgRadius: "",
    profileRadius: "",
  }),
  props: {
    bgImg: String,
    profileImg: String,
    profileName: String,
    profileTitle: String,
    textColor: String,
    bgBorder: String,
    profileBorder: String,
  },
  computed: {
  profileImgBorder() {
    let profileRadius;
    if (this.profileBorder === "100") {
      profileRadius = "100px";
    } else if (this.profileBorder === "30") {
      profileRadius = "30px";
    } else if (this.profileBorder === "10") {
      profileRadius = "10px";
    } else if (this.profileBorder === "0") {
      profileRadius = "0px";
    }

    return `
    border-radius: ${profileRadius};
    `;
  },
  bgImgBorder() {
    let bgRadius;
    if (this.bgBorder === "100") {
      bgRadius = "50% / 0 0 100% 100%";
    } else if (this.bgBorder === "80") {
      bgRadius = "40% / 0 0 100% 100%";
    } else if (this.bgBorder === "50") {
      bgRadius = "25% / 0 0 100% 100%";
    } else if (this.bgBorder === "0") {
      bgRadius = "0% / 0 0 100% 100%";
    }

    return `
    border-radius: ${bgRadius};
    `;
  },
},

  methods: {
    
  },
  mounted() {
    
  },
};
</script>
