<template>
  <div v-if="showBgImage" class="menu-parrent" :style="style">
    <div @click="toggleExpand">
      <img :src="itemBgImage" style="width: 100%" alt="" />
    </div>

    <div v-for="(menu, index) in menuData" :key="index">
      <v-expand-y-transition v-if="menu.visible == 'true'">
        <div
          :style="menu.item_imgs.length > 0 ? shadowStyle : null"
          v-show="menu.open"
          class="menu-items"
          width="100%"
          @click="openModal(menu)"
        >
          <div class="item">
            <span
              class="item-name"
              :style="`color:${menuOption.block_option.textBlockItemColor}`"
              >{{ menu.title }}</span
            >
            <span
              class="dots"
              :style="`border-bottom:1px dotted ${menuOption.block_option.textBlockItemColor}`"
            ></span>
            <span
              class="item-price"
              :style="`color:${menuOption.block_option.textBlockItemColor}`"
              >{{ menu.price }}</span
            >
          </div>
          <div
            :style="`color:${menuOption.block_option.textBlockItemColor}`"
            v-if="menu.item_imgs.length < 1"
            class="item-sub"
          >
            {{ menu.description }}
          </div>
        </div>
      </v-expand-y-transition>
    </div>
  </div>
  <menuModal
    @closeMenuModal="closeModal"
    v-if="showMenuModal"
    :menuData="menuModalData"
  />

  <div
    v-if="!showBgImage"
    class="menu-parrent"
    @click="toggleExpand"
    style="display: flex; flex-direction: column; gap: 16px"
  >
    <div
      class="menu-items"
      v-for="menu in filteredVisiblityItem"
      :key="menu.id"
      width="100%"
      :style="menu.item_imgs.length > 0 ? shadowStyle : null"
      @click="openModal(menu)"
    >
      <div class="item" >
        <span
          class="item-name"
          :style="`color:${menuOption.block_option.textBlockItemColor}`"
          >{{ menu.title }}</span
        >
        <span
          class="dots"
          :style="`border-bottom:1px dotted ${menuOption.block_option.textBlockItemColor}`"
        ></span>
        <span
          class="item-price"
          :style="`color:${menuOption.block_option.textBlockItemColor}`"
          >{{ menu.price }}</span
        >
      </div>
      <div
        :style="`color:${menuOption.block_option.textBlockItemColor}`"
        v-if="menu.item_imgs.length < 1"
        class="item-sub"
      >
        {{ menu.description }}
      </div>
    </div>
  </div>
</template>

<style>
@keyframes beat {
  0%, 50%, 100% {
    transform: scale(1, 1);
  }
  30%, 80% {
    transform: scale(0.96, 0.99);
  }
}

@keyframes beatWithPause {
  0% {
    transform: scale(1, 1);
  }
  10%,
  30% {
    transform: scale(0.96, 0.99);
  }
  20%, 50% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1, 1);
  }
}

.menu-parrent {
  width: 100%;
  min-height: 25px;
}
.menu-items {
  color: rgb(0, 0, 0) !important;
  padding: 8px;
}
.item-sub {
  text-align: start;
  direction: rtl;
  font-size: 14px;
}
.item {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  align-items: center;
  width: 100%;
}
.item-name {
  word-wrap: break-word;
  direction: rtl;
  font-weight: bold;
}
.item-price {
  white-space: nowrap;
  font-weight: bold;
}
.dots {
  flex-grow: 1;
  border-bottom: 1px dotted black;
  margin: 0 10px;
  height: 0;
}
</style>

<script>
/* eslint-disable */
import menuModal from "./menuModal.vue";
import router from "@/router";
export default {
  name: "menuComponent",
  components: {
    menuModal,
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
    openMenuId: {
      type: Number,
      required: true,
    },
    menuData: {
      type: Object,
      required: false,
    },
    menuOption: Object,
    blockTitle: String,
    disableInternalEvents: {
      type: Boolean,
      default: false,
    },
    style: {
      type: Object,
      default: () => ({}),
    },
  },

  data: () => ({
    itemBgImage: "",
    showBgImage: false,
    showMenuModal: false,
    menuModalData: [],
    shadowStyle: {
      
      // animation: "beat 1s ease-in-out infinite",
      animation: "beatWithPause 4s ease-in-out infinite",
    },
  }),
  computed: {
    filteredVisiblityItem() {
      return this.menuData.filter((menu) => menu.visible == 'true');
    },
    isOpen() {
      return this.openMenuId === this.id;
    },
  },
  watch: {
    isOpen(newVal) {
      if (newVal) {
        this.openCards();
      } else {
        this.closeCards();
      }
    },
  },
  create() {
    for (let menu of this.menuData) {
      menu["open"] = "false";
    }
  },
  mounted() {
    for (let menu of this.menuData) {
      if (menu.image) {
        this.itemBgImage = menu.image;
        this.showBgImage = true;
      }
    }
  },
  methods: {
    openModal(val) {
      if (!router.currentRoute._value.fullPath.includes("/edit/")) {
        this.menuModalData = val;
        if (val.item_imgs.length > 0) {
          this.showMenuModal = true;
        }
      }
    },
    closeModal() {
      this.showMenuModal = false;
    },
    toggleExpand() {
      if (this.disableInternalEvents) {
        event.stopPropagation(); // جلوگیری از انتشار ایونت داخلی
        this.$emit("click", event); // انتشار ایونت به والد
        return;
      }
      if (this.isOpen) {
        this.$emit("update:openMenuId", null);
      } else {
        this.$emit("update:openMenuId", this.id);
      }
    },
    openCards() {
      this.menuData.forEach((card, index) => {
        setTimeout(() => {
          card.open = true;
        }, index * 30);
      });
    },
    closeCards() {
      this.menuData.reverse().forEach((card, index) => {
        setTimeout(() => {
          card.open = false;
        }, index * 30);
      });
      this.menuData.reverse();
    },
  },
};
</script>
