<template>
  <div class="customer-club-form" component="customerClubForm">
    <div class="customer-club-form-content">
      <div class="customer-club-form-content-profile">
        <div
          class="customer-club-form-content-profile-select"
          @click="closeProfileModal"
        >
          انتخاب پروفایل
        </div>
        <div class="customer-club-form-content-profile-selected">
          <v-img
            lazy-src="./assets/images/content/defaultProfile.png"
            :src="this.selectedProfile.img"
            alt=""
            class="customer-club-form-content-profile-selected-image"
          />
          <div class="customer-club-form-content-profile-selected-name">
            {{ this.selectedProfile.title }}
          </div>
        </div>
      </div>
      <div class="customer-club-form-content-output">
        <div
          class="customer-club-form-content-output-features"
          v-for="(feature, index) in features"
          :key="feature.method"
          @click="showOutPut(index)"
        >
          <div class="customer-club-form-content-output-features-card">
            <div class="customer-club-form-content-output-features-card-img">
              <img
                :src="feature.image"
                class="customer-club-form-content-output-features-card-img-icon"
                alt=""
              />
            </div>
            <div class="customer-club-form-content-output-features-card-title">
              {{ feature.name }}
            </div>
          </div>
        </div>
      </div>
      <customerClub
        :option="this.clubBlockOption"
        :profile_id="this.selectedProfile.id"
        :club="this.clubFormData"
        v-if="this.clubLoading"
      />
      <!-- <div class="customer-club-form-content-submit">
        <button class="customer-club-form-content-submit-button">
          افزودن به باشگاه مشتریان
        </button>
      </div> -->
    </div>
    <selectProfileModal
      v-if="showProfileModal"
      @close="closeProfileModal"
      @changeProfile="changeProfile"
      :selectedProfile="selectedProfile"
      :profiles="this.profiles"
    />
    <customerList
      v-if="showCustomerList"
      :customers="this.outPutList"
      :profile_id="this.selectedProfile.id"
      @close="closeCustomerList"
    />
  </div>
</template>
<style lang="scss">
.customer-club-form-content-profile-selected-image {
  img {
    border-radius: 50% !important;
  }
}
</style>
<style lang="scss" scoped>
@import "./styles/index.scss";
</style>
<script>
/* eslint-disable */
import selectProfileModal from "./selectProfileModal.vue";
import axios from "axios";
import customeClub from "@/services/customerClub.js";
import customerClub from "@/components/customerClub/index.vue";
import previewConfig from "@/services/preview";
import customerList from "./customerList.vue";
export default {
  name: "customerClubForm",
  props: {
    profiles: Object,
  },
  watch: {
    selectedProfile: {
      handler() {
        this.getEdit();
      },
      deep: true,
    },
  },
  methods: {
    closeCustomerList() {
      this.showCustomerList = !this.showCustomerList;
    },
    changeProfile(val) {
      this.selectedProfile = val;
      this.showProfileModal = !this.showProfileModal;
    },
    closeProfileModal() {
      this.showProfileModal = !this.showProfileModal;
    },
    showOutPut(index) {
      if (index == 1) {
        axios(
          customeClub(localStorage.getItem("uuid"), this.selectedProfile.id)
        )
          .then((response) => {
            this.outPutList = response.data;
            this.closeCustomerList();
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },

    getEdit() {
      this.loading = true;
      this.clubLoading = false;

      const formData = new FormData();
      formData.append("link", this.selectedProfile.link);
      formData.append("user_uuid", localStorage.getItem("uuid"));
      formData.append("profile_id", this.selectedProfile.id);
      axios(previewConfig(formData))
        .then((response) => {
          if (response.data == "Profile not found") {
            alert("test");
          } else {
            response.data.blocks.forEach((block) => {
              if (block.club.length > 0) {
                this.createClubForm(block);
              }
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    createClubForm(block) {
      this.customerClubForm = block;
      this.clubBlockOption = this.customerClubForm.block_option;
      this.clubFormData = this.customerClubForm.club;
      this.clubLoading = true;
    },
  },
  components: {
    selectProfileModal,
    customerClub,
    customerList,
  },
  data: () => ({
    features: [
      {
        name: "پرینت",
        image: "../assets/images/icons/print.svg",
        method: "print",
      },
      {
        name: "لیست مشتریان",
        image: "../assets/images/icons/list.svg",
        method: "list",
      },
      {
        name: "دانلود اکسل",
        image: "../assets/images/icons/excel.svg",
        method: "excel",
      },
    ],
    selectedProfile: [],
    showProfileModal: false,
    customerClubForm: [],
    clubFormData: [],
    clubBlockOption: [],
    clubLoading: false,
    outPutList: [],
    showCustomerList: false,
  }),
  mounted() {
    this.selectedProfile = this.profiles[0];
    this.getEdit();
  },
};
</script>
