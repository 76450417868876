<template>
  <div v-if="!showAddModal" class="page-creator-footer" component="pageCreatorFooter">
    <div class="footer">
      <div class="add-page">
        <v-btn @click="showModal" class="add-page-button bg-green">
          <v-icon class="text-white">
            mdi-plus
          </v-icon>
        </v-btn>
      </div>
    </div>
    <!-- <div class="create-new-page">
      <v-btn height="50"></v-btn>
    </div>
    <div class="footer">
      <v-btn class="footer-shop"><v-img class="footer-shop-img" src="@/assets/images/content/shopping-cart.png"></v-img></v-btn>
      <div class="footer-menu"><v-img class="footer-menu-img" src="@/assets/images/content/Category.png"></v-img></div>
      <v-btn class="footer-profile"><v-img class="footer-profile-img" src="@/assets/images/content/Profile.png"></v-img></v-btn>
    </div> -->
  </div>
  <addPageModal @showModal="showModal" v-else />
</template>
<style lang="scss">
@import "./styles/index.scss";
</style>
<script>
import addPageModal from "@/components/addPage/index.vue"
export default {
  name: "pageCreatorFooter",
  data:()=>({
    showAddModal:false,
  }),
  methods: {
    showModal(){
      this.showAddModal=!this.showAddModal
    },
  },
  components:{
    addPageModal
  }

};
</script>