<template>
  <div class="app">
    <div class="ezy-app">
      <slidMenu />
      <router-view />
    </div>
  </div>
</template>
<script>
import slidMenu from "@/components/slideMenu/index.vue";
export default {
  components: {
    slidMenu,
  },

  mounted(){
    
  }
};
</script>
<style lang="scss">
*{
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
}
input,textarea{
  user-select: auto !important;
  -moz-user-select: auto !important;
  -webkit-user-select: auto !important;
}
@font-face {
  font-family: "bNazanin";
  src: url("@/assets/fonts/fa/B-NAZANIN.TTF") format("truetype");
}
@font-face {
  font-family: "iransansweb";
  src: url("@/assets/fonts/fa/iransansweb.woff")
    format("truetype");
}
@font-face {
  font-family: "anjoman";
  src: url("@/assets/fonts/fa/anjoman.woff")
    format("truetype");
}
@font-face {
  font-family: "bardiya";
  src: url("@/assets/fonts/fa/bardiya.ttf")
    format("truetype");
}
@font-face {
  font-family: "dana-regular";
  src: url("@/assets/fonts/fa/dana-regular.woff")
    format("truetype");
}
@font-face {
  font-family: "darab-regular-medium";
  src: url("@/assets/fonts/fa/darab-regular-medium.woff")
    format("truetype");
}
@font-face {
  font-family: "dinar-light";
  src: url("@/assets/fonts/fa/dinar-light.ttf")
    format("truetype");
}
@font-face {
  font-family: "ebtekar";
  src: url("@/assets/fonts/fa/ebtekar.woff")
    format("truetype");
}
@font-face {
  font-family: "falsafeh-light";
  src: url("@/assets/fonts/fa/falsafeh-light.woff")
    format("truetype");
}
@font-face {
  font-family: "gohar-light";
  src: url("@/assets/fonts/fa/gohar-light.woff")
    format("truetype");
}
@font-face {
  font-family: "iransansdn";
  src: url("@/assets/fonts/fa/iransansdn.woff")
    format("truetype");
}
@font-face {
  font-family: "iranyekanwebregular";
  src: url("@/assets/fonts/fa/iranyekanwebregular.woff")
    format("truetype");
}
@font-face {
  font-family: "kalamehweb_regular";
  src: url("@/assets/fonts/fa/kalamehweb_regular.woff")
    format("truetype");
}
@font-face {
  font-family: "kamran-bold";
  src: url("@/assets/fonts/fa/kamran-bold.ttf")
    format("truetype");
}
@font-face {
  font-family: "kufi";
  src: url("@/assets/fonts/fa/kufi.TTF")
    format("truetype");
}
@font-face {
  font-family: "morabba-regular";
  src: url("@/assets/fonts/fa/morabba-regular.woff")
    format("truetype");
}
@font-face {
  font-family: "paykan-regular";
  src: url("@/assets/fonts/fa/paykan-regular.woff")
    format("truetype");
}
@font-face {
  font-family: "shabnam";
  src: url("@/assets/fonts/fa/shabnam.woff")
    format("truetype");
}
@font-face {
  font-family: "titr-stancil";
  src: url("@/assets/fonts/fa/titr-stancil.ttf")
    format("truetype");
}
@font-face {
  font-family: "traffic";
  src: url("@/assets/fonts/fa/traffic.ttf")
    format("truetype");
}
@font-face {
  font-family: "vazir";
  src: url("@/assets/fonts/fa/vazir.woff")
    format("truetype");
}
@font-face {
  font-family: "Yekta";
  src: url("@/assets/fonts/fa/Yekta.ttf")
    format("truetype");
}








@font-face {
  font-family: "adobe-clean-regular";
  src: url("@/assets/fonts/en/adobe-clean-regular.woff")
    format("truetype");
}
@font-face {
  font-family: "caslon-regular";
  src: url("@/assets/fonts/en/caslon-regular.woff")
    format("truetype");
}
@font-face {
  font-family: "coves-light";
  src: url("@/assets/fonts/en/coves-light.woff")
    format("truetype");
}
@font-face {
  font-family: "dancing-script-regular";
  src: url("@/assets/fonts/en/dancing-script-regular.ttf")
    format("truetype");
}
@font-face {
  font-family: "dosis-regular";
  src: url("@/assets/fonts/en/dosis-regular.ttf")
    format("truetype");
}
@font-face {
  font-family: "helvetica";
  src: url("@/assets/fonts/en/helvetica.woff")
    format("truetype");
}
@font-face {
  font-family: "montserrat-regular";
  src: url("@/assets/fonts/en/montserrat-regular.woff")
    format("truetype");
}
@font-face {
  font-family: "neue-haas-grotesk-roman";
  src: url("@/assets/fonts/en/neue-haas-grotesk-roman.woff")
    format("truetype");
}
@font-face {
  font-family: "orbitron-regular";
  src: url("@/assets/fonts/en/orbitron-regular.ttf")
    format("truetype");
}
@font-face {
  font-family: "poppins-regular";
  src: url("@/assets/fonts/en/poppins-regular.woff")
    format("truetype");
}
@font-face {
  font-family: "roboto-regular";
  src: url("@/assets/fonts/en/roboto-regular.woff")
    format("truetype");
}
@font-face {
  font-family: "times-new-roman";
  src: url("@/assets/fonts/en/times-new-roman.woff")
    format("truetype");
}
@font-face {
  font-family: "ubuntu-regular";
  src: url("@/assets/fonts/en/ubuntu-regular.woff")
    format("truetype");
}

// @font-face {
//   font-family: "Orbitron";
//   src: url("@/assets/fonts/orbitron/font.ttf") format("truetype");
// }
// * {
//   font-family: "bNazanin", sans-serif;
//   text-decoration: none;
// }
.swal2-container{
  font-family:'morabba-regular','poppins-regular' ;
}
body {
  background: rgb(110, 110, 110);
  overflow-y: hidden;
        overflow-x: hidden;
}
.app {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: hidden;
        overflow-x: hidden;
  .ezy-app {
    background-color: white;
    height: inherit;
    min-width: 330px;
    max-width: 600px;
    width: 100%;
    // height: 100vh;
    z-index: 1;
    overflow-y: hidden;
    overflow-x: hidden;
    @media (max-width: 600px) {
      // min-height: 100vh;
      // height: inherit;
      &{
        // height:calc(100vh );
        
      }
    }
  }
}
</style>
