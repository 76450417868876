<template>
    <div class="empty-result-page" component="emptyResultPage">
        <div class="empty-result">
            <div class="empty-result-picture">
                <img class="empty-result-picture-img" src="@/assets/images/content/empty-page.jpg">
            </div>
            <div class="empty-result-title">
                <p class="empty-result-title-text">هنوز صفحه ای ایجاد نکردی</p>
            </div>
        </div>
    </div>
</template>
<style lang="scss">
@import "./styles/index.scss";
</style>
<script>
export default {
  name: "emptyResultPage",
  data: () => ({

  }),

}
</script>