import baseUrl from "./baseUrl";

function selectedCustomer(id,uuid,profile_id) {
  return {
    method: 'get',
    url: `${baseUrl}/api/v1/profile/subscribers/${id}?user_uuid=${uuid}&profile_id=${profile_id}`,
    headers: { 
       'Accept': 'application/json', 
    },
 }

  
}

export default selectedCustomer;
