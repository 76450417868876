<template>
  <div
    class="selected-customer-modal"
    @click.self="$emit('close')"
    component="selectedCustomerModal"
    v-if="loading"
  >
    <div class="selected-customer-modal-content">
      <div class="selected-customer-modal-content-list">
        <div class="selected-customer-modal-content-list-item">
          <div class="selected-customer-modal-content-list-item-key">
            <div v-for="(information, index) in customeData[customer]" :key="index">
              {{ information.key + ":" }}
            </div>
          </div>
          <div class="selected-customer-modal-content-list-item-value">
            <div v-for="(information, index) in customeData[customer]" :key="index">
              {{ information.value }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.selected-customer-modal {
  position: absolute;
  top: 0;
  left: 0;
  background: #80808085;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;

  &-content {
    background: white;
    padding: 40px;
    border-radius: 20px;
    &-list {
      &-item {
        display: flex;
        flex-direction: row-reverse;
        gap: 16px;
        &-key {
          text-align: center;
          display: flex;
          flex-direction: column;
          gap: 8px;
          div {
            direction: rtl;
          }
        }
        &-value {
          text-align: center;
          display: flex;
          flex-direction: column;
          gap: 8px;
          div {
          }
        }
      }
    }
  }
}
</style>
<script>
import selectedCustomer from "@/services/selectedCustomer.js"
import axios from "axios";
export default {
  name: "selectedCustomerModal",
  data: () => ({
    customeData:[],
    loading:false,
  }),
  props: {
    customer: Object,
    profile_id:Number,
  },
  methods:{
    getCustomerData(){
        axios(
            selectedCustomer(this.customer,localStorage.getItem("uuid"), this.profile_id)
        )
          .then((response) => {
            this.customeData=response.data
            this.loading=true
          })
          .catch((error) => {
            console.log(error);
          });
    }
  },
  mounted(){
    this.getCustomerData()
  },
};
</script>
