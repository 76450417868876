<template>
  <div
    @click.self="$emit('closeMenuModal')"
    class="menu-modal"
    component="menuModal"
  >
    <div class="menu-modal-card">
      <div class="menu-modal-card-header">
        <v-icon
          class="menu-modal-card-header-close"
          @click="$emit('closeMenuModal')"
          >mdi-close-circle-outline</v-icon
        >
        <div class="menu-modal-card-header-title">{{ menuData.title }}</div>
      </div>
      <div class="menu-modal-card-imgs">
        <v-img height="300px" :src="selectedImage"> </v-img>
      </div>
      <div class="menu-modal-card-slider">
        <div
          @click="selectImage(img.image)"
          height="100px"
          width="100px"
          class="menu-modal-card-slider-images"
          v-for="img in menuData.item_imgs"
          :key="img.id"
          :style="
            selectedImage == img.image
              ? `background-image: url(${img.image});border-bottom:3px solid #00A407;`
              : `background-image: url(${img.image});padding-bottom:3px;`
          "
        ></div>
      </div>
      <div
        class="menu-modal-card-des"
        v-if="menuData.description || menuData.price"
      >
        <div class="menu-modal-card-des-title" v-if="menuData.description">
          {{ menuData.description }}
        </div>
        <div class="menu-modal-card-des-price" v-if="menuData.price">
          {{ menuData.price }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
export default {
  name: "menuModal",
  data: () => ({
    selectedImage: "",
  }),
  props: {
    menuData: Object,

    disableInternalEvents: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    selectImage(src) {
      this.selectedImage = src;
    },
  },
  mounted() {
    this.selectedImage = this.menuData.item_imgs[0].image;
    let mouseDown = false;
    let startX, scrollLeft;
    const slider = document.querySelector(".menu-modal-card-slider");

    const startDragging = (e) => {
      mouseDown = true;
      startX = e.pageX - slider.offsetLeft;
      scrollLeft = slider.scrollLeft;
    };

    const stopDragging = (e) => {
      mouseDown = false;
    };

    const move = (e) => {
      e.preventDefault();
      if (!mouseDown) {
        return;
      }
      const x = e.pageX - slider.offsetLeft;
      const scroll = x - startX;
      slider.scrollLeft = scrollLeft - scroll;
    };

    // Add the event listeners
    slider.addEventListener("mousemove", move, false);
    slider.addEventListener("mousedown", startDragging, false);
    slider.addEventListener("mouseup", stopDragging, false);
    slider.addEventListener("mouseleave", stopDragging, false);
  },
};
</script>

<style lang="scss" scoped>
.menu-modal {
  position: fixed;
  background: #808080c4;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  i {
    align-self: start;
  }
  &-card {
    width: 100%;
    margin: 8px;
    max-width: 584px;
    background: white;
    min-width: 330px;
    display: flex;
    border-radius: 16px;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    gap: 8px;
    &-header {
      width: 100%;
      display: flex;
      flex-direction: row-reverse;
      padding: 12px 12px 8px;
      box-shadow: 0px 1px 5px 0px #959595;
      &-close {
        color: #9f9f9f;
      }
      &-title {
        flex: 1;
        text-align: center;
        padding-left: 24px;
      }
    }
    &-imgs {
      width: 100%;
      padding: 0px 16px;
    }
    &-slider {
      display: flex;
      gap: 8px;
      width: 100%;
      overflow-x: scroll;
      padding: 8px 16px;
      -ms-overflow-style: none;
      scrollbar-width: none;
      align-items: center;
      justify-content: flex-start;
      ::-webkit-scrollbar {
        width: 0 !important;
        height: 0 !important;
      }
      &-images {
        width: 100px;
        height: 100px;
        background-size: contain;
        background-position: bottom;
        min-width: 100px;
      }
    }
    &-des {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 24px 32px 8px 32px;
      &-title {
        text-wrap: wrap;
        text-align: center;
        padding: 0px 16px 8px 16px;
      }
      &-price {
        font-size: 24px;
        line-height: 32px;
        font-weight: 400;
      }
    }
  }
}
</style>
