<template>
  <div class="ezy-text" component="ezyText">
    <div :style="textStyle" class="text-block">
      {{ data.text }}
    </div>
  </div>
</template>

<style>
.ezy-text {
  width: 100%;
  min-height: 25px;
}
.text-block {
  margin: 0 0 22px;
  width: 100%;
  white-space: pre-line;
  word-break: break-word;
  unicode-bidi: plaintext;
  font-family: inherit;
}
</style>

<script>
export default {
  name: "textBlock",
  data: () => ({
    data: [], 
  }),
  props: {
    textData:Object
  },
  watch: {
    textData: {
      handler(newTextData) {
        this.initializeMenuData(newTextData);
      },
      deep: true,
    },
  },
  mounted() {
    if (this.textData) {
      this.initializeMenuData(this.textData);
    }
  },
  methods: {
  initializeMenuData(text) {
    if (Array.isArray(text) && text.length > 0) {
      this.data = text[0];
    } else {
      this.data = {}; 
    }
  },
},
  computed: {
    textStyle() {
      if (this.data && this.data.textSize && this.data.textAlign && this.data.textColor) {
        
        return `
           ${this.data.textSize}
          ${this.data.textAlign}
          color:${this.data.textColor};
        `;
      }
      return {};
    },
  },
};
</script>
