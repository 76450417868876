<template>
  <div class="pageCreator" :style="[slideMenuStyle]" component="pageCreator">
    <page-Creator-Header @showSlideMenu="showSlideMenu()" />
    <page-creator-content />
    <page-Creator-Footer />
  </div>
</template>
<style lang="scss">
@import "./styles/index.scss";
</style>
<script>
import pageCreatorHeader from "@/components/pageCreatorHeader/index.vue";
import pageCreatorContent from "@/components/pageCreatorContent/index.vue";
import pageCreatorFooter from "@/components/pageCreatorFooter/index.vue";
import router from "@/router/index.js";

export default {
  name: "pageCreatorComponent",
  data: () => ({
    isSlideMenuVisible: false,
  }),
  components: {
    pageCreatorHeader,
    pageCreatorContent,
    pageCreatorFooter,
  },
  mounted() {
    const uuid = localStorage.getItem("uuid");
    if (!uuid || uuid.length === 0) {
      router.replace("/");
    }
  },
  computed: {
    slideMenuStyle() {
      if (this.isSlideMenuVisible) {
        return `
          transform: scale(0.8) !important;
          right: 60% !important;
          overflow: auto;
          pointer-events: none;
          transition: all ease-in-out 250ms !important;
          top: 20% !important;
          height: 80vh !important;
          padding-bottom: 0 !important;
          box-shadow: 0 0 30px 0 !important;
          position: relative;
          border-radius: 30px;
        `;
      } else {
        return `
          right: 0% !important;
          transition: all ease-in-out 250ms !important;
          position: relative;
        `;
      }
    },
  },
  methods: {
    showSlideMenu() {
      this.isSlideMenuVisible = !this.isSlideMenuVisible;
      if (this.isSlideMenuVisible) {
        document.querySelector(".slider").style.display = "flex";
      } else {
        document.querySelector(".slider").style.display = "none";
      }
    },
  },
};
</script>
