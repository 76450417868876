// main.js
import { createApp } from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify.js'
import router from './router'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import VueClipboard from 'vue-clipboard2'
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import VueQRCodeComponent from 'vue-qrcode-component'
import draggable from 'vuedraggable'
import './registerServiceWorker'
import Vue3OpenLayers from 'vue3-openlayers';
import 'vue3-openlayers/dist/vue3-openlayers.css';
import Countdown from "vue3-flip-countdown";

const app = createApp(App)
/* eslint-disable */
app.component('qr-code', VueQRCodeComponent)
app.component('draggable', draggable)
app.use(Vue3OpenLayers);
app.use(vuetify,{
    rtl:true
})
app.use(router)
app.use(VueAxios, axios)
app.use(VueSweetalert2)
app.use(VueClipboard)
app.use(draggable)
app.use(Countdown)

app.mount('#app')
